<template>
  <div
    class="w-full h-screen lg:bg-[#F5F5F5] sm:bg-[#F5F5F5] bg-[#FFFFFF] overflow-hidden"
  >
    <PageHeader showSvg />
    <div class="pt-28 flex justify-center">
      <div
        class="lg:bg-[#FFFFFF] sm:bg-[#FFFFFF] w-[395px] p-6 lg:rounded-[10px] sm:rounded-[10px] lg:border-b sm:border-b lg:border-[#F5F5F5] sm:border-[#F5F5F5] lg:shadow-lg sm:shadow-lg shadow-neutral-200/50"
      >
        <div>
          <!-- <div v-if="view !== 'review'" class="pb-6"> -->
          <p class="text-[#000000] font-semibold text-xl pb-1 tracking-wide">
            Welcome to your cooperative portal
          </p>
          <p class="text-[#666666] text-sm font-normal">
            Update your records to proceed
          </p>
          <!-- </div> -->

          <form class="w-[341px]" @submit.prevent="onSubmit">
            <div v-if="view === 'firstStage'">
              <div class="flex flex-col w-full py-3 pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >First Name</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="Your first name"
                  v-model="user_store.userProfile.firstName"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Last Name</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="Your last name or surname"
                  v-model="user_store.userProfile.lastName"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Phone</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="phone"
                  placeholder="080****"
                  v-model="user_store.userProfile.phone"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >NIN</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="Enter NIN number"
                  v-model="user_store.userProfile.nin"
                />
              </div>
              <div class="text-white pt-4 pb-2">
                <button
                  @click="view = 'secondStage'"
                  class="bg-[#2F4A89] rounded-md h-[40px] w-full"
                >
                  <span>Next</span>
                </button>
              </div>
            </div>

            <div v-if="view === 'secondStage'">
              <div class="flex flex-col w-full py-3 pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Residential address</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="address"
                  placeholder="Where do you live?"
                  v-model="user_store.userProfile.residentialAddress"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Office address</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="address"
                  placeholder="Where is your office/business located?"
                  v-model="user_store.userProfile.officeAddress"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Date of birth</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="date"
                  placeholder="03/05/2023"
                  v-model="user_store.userProfile.dob"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Date of appointment</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="date"
                  placeholder="03/05/2023"
                  v-model="user_store.userProfile.doe"
                />
              </div>
              <div class="pt-4 pb-2 flex gap-2">
                <button
                  @click="view = 'firstStage'"
                  class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
                >
                  <span>Back</span>
                </button>
                <button
                  @click="view = 'thirdStage'"
                  class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
                >
                  <span>Next</span>
                </button>
              </div>
            </div>

            <div v-if="view === 'thirdStage'">
              <div class="flex flex-col py-3 w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Next of kin name</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="Full name of next of kin"
                  v-model="user_store.userProfile.nokName"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Relationship with next of kin</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="text"
                  placeholder="How is your next of kin related to you?"
                  v-model="user_store.userProfile.nokRel"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Next of kin telephone</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="phone"
                  placeholder="08011223344"
                  v-model="user_store.userProfile.nokTel"
                />
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Next of kin address</label
                >
                <input
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="address"
                  placeholder="where is your next of kin address"
                  v-model="user_store.userProfile.nokAddress"
                />
              </div>
              <div class="pt-4 pb-2 flex gap-2">
                <button
                  @click="view = 'secondStage'"
                  class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
                >
                  <span>Back</span>
                </button>
                <button
                  @click="view = 'forthStage'"
                  class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
                >
                  <span>Next</span>
                </button>
              </div>
            </div>

            <div v-if="view === 'forthStage'">
              <div class="flex flex-col w-full py-3 pb-4 relative">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Agency</label
                >
                <div class="absolute right-2 top-[44px]">
                  <img src="@/assets/img/inputMore.svg" alt="more" />
                </div>
                <select
                  class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                  name="agency"
                  id="agency"
                  placeholder="choose an agency"
                  v-model="user_store.userProfile.agency_id"
                >
                  <option value="" disabled>Select your agency</option>
                  <option
                    v-for="item in user_store?.agencies"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col w-full pb-4">
                <label class="text-[#222222] text-[13px] font-medium pb-1"
                  >Upload passport</label
                >
                <div
                  class="flex border px-3 py-4 bg-[#fbfcfc] border-dashed border-[#2F4A89] rounded-md"
                >
                  <input
                    class="absolute left-[-100%] h-[48px] w-[200%] text-xs cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0"
                    type="file"
                    @change="upload($event)"
                  />
                  <div class="flex items-center">
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="38"
                        height="38"
                        rx="19"
                        fill="#006A51"
                        fill-opacity="0.1"
                      />
                      <path
                        d="M28 26V12C28 10.9 27.1 10 26 10H12C10.9 10 10 10.9 10 12V26C10 27.1 10.9 28 12 28H26C27.1 28 28 27.1 28 26ZM15.9 20.98L18 23.51L21.1 19.52C21.3 19.26 21.7 19.26 21.9 19.53L25.41 24.21C25.66 24.54 25.42 25.01 25.01 25.01H13.02C12.6 25.01 12.37 24.53 12.63 24.2L15.12 21C15.31 20.74 15.69 20.73 15.9 20.98Z"
                        fill="#2F4A89"
                      />
                    </svg>
                    <div
                      v-if="user_store.userProfile.image_path === ''"
                      class="text-xs pl-5 leading-5 px-6"
                    >
                      <p class="text-[#222222]">Select an image to upload or</p>
                      <p class="font-normal text-[#2F4A89]">click to browse</p>
                    </div>
                    <div v-else class="text-xs pl-5 leading-5 px-6 pr-[40px]">
                      <p class="text-[#222222]">
                        {{
                          user_store.userProfile.image_path.substring(0, 50) +
                          '...'
                        }}
                      </p>
                      <p class="font-normal text-[#2F4A89]">Remove file</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-4 pb-2 flex gap-2">
                <button
                  @click="view = 'thirdStage'"
                  class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
                >
                  <span>Back</span>
                </button>
                <button
                  type="submit"
                  class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white text-sm"
                >
                  <span>Update Profile</span>
                </button>
              </div>
            </div>

            <!-- <div v-if="view === 'review'">
              <p class="text-[#000000] font-bold text-xl">Review and submit</p>
              <div class="flex gap-4 pt-8 pb-4">
                <div class="w-[50%]">
                  <p class="text-[#888888] text-[10px] font-bold">FIRST NAME</p>
                  <p class="text-[#222222] font-medium text-xs">Chike</p>
                </div>
                <div class="w-[50%]">
                  <p class="text-[#888888] text-[10px] font-bold">LAST NAME</p>
                  <p class="text-[#222222] font-medium text-xs">Opara</p>
                </div>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold">
                  EMAIL ADDRESS
                </p>
                <p class="text-[#222222] font-medium text-xs">
                  chikexyz@domain.com
                </p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Residential address
                </p>
                <p class="text-[#222222] font-medium text-xs">
                  1, anywhere street, Ikeja, Lagos
                </p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Office address
                </p>
                <p class="text-[#222222] font-medium text-xs">
                  123, Somewhere street, Ikoyi, Lagos
                </p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Date of birth
                </p>
                <p class="text-[#222222] font-medium text-xs">17/04/1914</p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Date of appoitnment
                </p>
                <p class="text-[#222222] font-medium text-xs">17/04/1934</p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Next of kin
                </p>
                <p class="text-[#222222] font-medium text-xs">Shina Peters</p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Relationship with next of kin
                </p>
                <p class="text-[#222222] font-medium text-xs">Uncle</p>
              </div>
              <div class="pb-4">
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Next of kin telephone
                </p>
                <p class="text-[#222222] font-medium text-xs">08189357878</p>
              </div>
              <div>
                <p class="text-[#888888] text-[10px] font-bold uppercase">
                  Next of kin address
                </p>
                <p class="text-[#222222] font-medium text-xs">
                  1, Village street, Abule
                </p>
              </div>
              <div class="pt-4 pb-2 flex gap-2">
                <button
                  @click="view = 'forthStage'"
                  class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
                >
                  <span v-if="user_store.loadingStatus">Loading...</span>
                  <span>Back</span>
                </button>
                <button
                  type="submit"
                  class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
                >
                  <span>Submit</span>
                </button>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/layout/PageHeader.vue';
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
import * as Yup from 'yup';

export default {
  name: 'UpdateCustomer',
  components: { PageHeader },
  setup() {
    const schema = Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      nin: Yup.string().required('NIN is required'),
      agency_id: Yup.string().required('Agency is required'),
      residential: Yup.string().required('House Address is required'),
      offAddress: Yup.string().required('Office Address is required'),
      dob: Yup.string()
        .required('Date of Birth is required')
        .matches(
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
          'Date of Birth must be a valid date in the format YYYY-MM-DD'
        ),
      doe: Yup.string()
        .required('Date of Appointment is required')
        .matches(
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
          'Date of Appointment must be a valid date in the format YYYY-MM-DD'
        ),
      nokName: Yup.string().required('Next of kin name is required'),
      nokRel: Yup.string().required('Next of kin relationship is required'),
      nokTel: Yup.string().required('Next of kin telephone is required'),
      nokAddress: Yup.string().required('Next of kin address is required'),
      image: Yup.string().required('Upload your photo'),
    });

    const user_store = useUserStore();

    return { user_store, schema };
  },

  data() {
    return {
      view: 'firstStage',
    };
  },
  created() {
    this.user_store.getAgency().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    upload(e) {
      let a = e.target.files[0];
      this.user_store.userProfile.image_path = a.name;
    },

    onSubmit() {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        firstName: this.user_store.userProfile.firstName,
        lastName: this.user_store.userProfile.lastName,
        phone: this.user_store.userProfile.phone,
        nin: this.user_store.userProfile.nin,
        agency: this.user_store.userProfile.agency_id,
        offAddress: this.user_store.userProfile.officeAddress,
        residential: this.user_store.userProfile.residentialAddress,
        dob: this.user_store.userProfile.dob,
        doe: this.user_store.userProfile.doe,
        nokName: this.user_store.userProfile.nokName,
        nokTel: this.user_store.userProfile.nokTel,
        nokRel: this.user_store.userProfile.nokRel,
        nokAddress: this.user_store.userProfile.nokAddress,
        image: this.user_store.userProfile.image_path,
      };
      console.log(payload);
      this.user_store
        .updateUserProfile(payload)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            this.user_store.setLoading(false);
            toast.success('User profile updated successful');
            this.$router.push('/login');
          } else {
            this.user_store.setLoading(false);
            toast.error('Failed to update user profile');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('Failed to update user profile');
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss"></style>
