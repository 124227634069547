<template>
  <div class="hidden lg:block">
    <table class="w-full z-[9999999] border-[10px] border-white">
      <thead class="bg-[#F4F7FF]">
        <tr class="h-11">
          <th
            class="Work Sans normal h-[18px] w-[50px] pl-4 text-left text-[12px] font-[500] leading-[18px] text-[#667085]"
          >
            <input
              class="form-checkbox h-[20px] w-[20px] mt-1 cursor-pointer rounded-[6px] bg-[#F4F7FF] border-[#CCCCCC] text-[#2F4A89] outline-none focus:outline-none focus:ring-0"
              type="checkbox"
              id=""
              name="allSelect"
            />
          </th>
          <th
            v-for="item in tableHeader"
            :key="item.id"
            :class="[
              'text-start text-xs font-medium text-[#111111]',
              item.id === 1 && 'pl-2',
            ]"
          >
            <p class="text-[14px] font-bold">{{ item.name }}</p>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="tableData?.length === 0">
          <tr>
            <td colspan="10" class="text-center py-20">No data available</td>
          </tr>
        </template>
        <template v-if="loadingStatus === true">
          <LoadingState />
        </template>
        <template v-else>
          <tr
            v-for="data in tableData"
            :key="data?.id"
            class="relative h-[48px] cursor-pointer border-b border-[#eaecf0] hover:bg-[#F4F7FF]"
          >
            <td class="pl-4">
              <input
                class="h-5 w-5 mt-1 rounded-[6px] border-b border-[#CCCCCC] bg-transparent bg-[#2F4A89] outline-none focus:outline-none focus:ring-0"
                type="checkbox"
                name="{el.uuid}"
              />
            </td>
            <td>
              <p class="text-sm text-[#101828] font-medium pl-2">
                LN/APP/000/{{ data?.id }}
              </p>
            </td>
            <td>
              <p class="text-sm text-[#101828] font-medium">
                {{ data?.member?.phone ? data?.member?.phone : 'N/A' }}
              </p>
            </td>
            <td>
              <p class="text-sm text-[#101828] font-bold">
                {{ formatNumber(data?.liability) }}
              </p>
            </td>
            <td>
              <p
                :class="[
                  'text-sm text-[#666666] font-medium',
                  data?.status == 1 ? 'text-orange-400' : data?.status == 2 ? 'text-green-400' : data?.status == 3 ? 'text-red-400' : ''
                ]"
              >
                {{ data?.status == 1 ? 'Pending' : data?.status == 2 ? 'Approved' : data?.status == 3 ? 'Declined' : '' }}
              </p>
            </td>
            <td>
              <button class="text-sm text-white bg-[#2F4A89] px-3 py-1 rounded-md font-bold">
                View
              </button>
            </td>
            <td>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="7.99984" cy="3.33333" r="1.33333" fill="#888888" />
                <circle cx="7.99984" cy="8.00033" r="1.33333" fill="#888888" />
                <ellipse
                  cx="7.99984"
                  cy="12.6663"
                  rx="1.33333"
                  ry="1.33333"
                  fill="#888888"
                />
              </svg>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Mobile Responsive -->
  <div class="block lg:hidden my-4 mb-10">
    <div v-if="tableData?.length === 0">
      <div>
        <p class="text-center py-20 font-bold">No data available</p>
      </div>
    </div>
    <div class="w-full h-full flex justify-center py-40" v-if="loadingStatus === true">
      <div class="lds-hourglass"></div>
    </div>
    <div v-else v-for="data in tableData" :key="data?.id" class="w-full p-4 bg-[#FFFFFF] border border-[#F5F5F5] my-3 rounded-lg">
      <div class="flex justify-between items-center py-1">
        <p>LN/APP/000/{{ data?.id }}</p>
        <p class="text-[#444444] text-sm font-bold ">₦{{ formatNumber(data?.liability) }}</p>
      </div>
      <div class="flex justify-between items-center py-1">
        <p class="text-[#444444] text-[16px] font-medium">{{ data?.member?.phone ? data?.member?.phone : 'N/A' }}</p> 
      </div>
      <div class="flex justify-between items-center py-1">
        <button class="text-sm text-white bg-[#2F4A89] px-3 py-1 rounded-md font-bold">
          View
        </button>
        <p :class="['px-3 py-1 rounded-md text-[13px]', data?.status == 1 ? 'text-orange-400 bg-orange-50' : data?.status == 2 ? 'text-green-400 bg-green-50' : data?.status == 3 ? 'text-red-400 bg-red-50' : '']">
          {{ data?.status == 1 ? 'Pending' : data?.status == 2 ? 'Approved' : data?.status == 3 ? 'Declined' : ''}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNumber, formatDays } from '@/helper/util';
import LoadingState from '../global/LoadingState.vue';
import { useGuarantorStore } from '@/stores/guarantor';

export default {
  name: 'GuarantorTable',
  components: {LoadingState},
  setup() {
    const guarantor_store = useGuarantorStore();
    return { guarantor_store };
  },
  props: {
    tableHeader: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableType: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log(this.tableType, this.tableData);
  },  
  computed: {
    loadingStatus() {
      return useGuarantorStore().loadingStatus;
    },
  },
  methods: {
    formatNumber(currency) {
      return formatNumber(currency);
    },
    formatDays(days) {
      return formatDays(days);
    },
  },
};
</script>
<style lang=""></style>
