<template>
  <div>
    <PageLayout />
    <div class="py-28 lg:pl-[16%]">
      <div class="px-4">Coming soon...</div>
    </div>
  </div>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout.vue';

export default {
    name: "VotingPage",
    components: { PageLayout }
}
</script>
<style lang="">
  
</style>