import { defineStore } from 'pinia';
import loanService from '@/helper/services/api';

export const useLoanStore = defineStore('loanStore', {
  state: () => ({
    loanRecords: [],
    repaymentRecords: [],
    submittedRecords: [],
    loanProfiles: [],
    loanProducts: [],
    repaymentTypes: [],
    runningLoans: [],
    loanProduct: null,
    loading: false,
  }),
  getters: {
    loadingStatus: (state) => state.loading,
  },
  actions: {
    setLoading(status) {
      this.loading = status;
    },
    async fetchLoanRecords() {
      try {
        this.loading = true;
        const { data } = await loanService.getLoanRecords();
        this.loanRecords = data;
        this.loading = false;
        // console.log('loan records', data);
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchLoanRepaymentRecords() {
      try {
        this.loading = true;
        const { data } = await loanService.getLoanRepaymentRecords();
        this.repaymentRecords = data;
        this.loading = false;
        // console.log('loan repayment records', data);
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchSubmittedRepaymentRecords() {
      try {
        this.loading = true;
        const { data } = await loanService.getSubmittedRepaymentRecords();
        this.submittedRecords = data;
        this.loading = false;
        console.log('submitted repayment records', data);
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchLoanProfiles() {
      try {
        const { data } = await loanService.getLoanProfiles();
        this.loanProfiles = data;
        // console.log('loan profiles', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLoanProducts(id) {
      try {
        const { data } = await loanService.getLoanProducts(id);
        this.loanProducts = data;
        console.log('loan products', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchLoanRepaymentTypes() {
      try {
        const { data } = await loanService.getLoanRepaymentTypes();
        this.repaymentTypes = data;
        // console.log('repayment types', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async newLoan(payload) {
      try {
        const res = await loanService.newLoan(payload);
        console.log('new loan', res.data);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async fetchRunningLoans() {
      try {
        const { data } = await loanService.getRunningLoans();
        this.runningLoans = data;
        console.log('running loans', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async loanRepayment(payload) {
      try {
        const res = await loanService.loanRepayment(payload);
        console.log('new loan', res.data);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async fetchLoanProduct(id) {
      try {
        const { data } = await loanService.getLoanProduct(id);
        this.loanProduct = data;
        console.log('loan product!!!', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
