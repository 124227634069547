import DashboardPage from '@/pages/DashboardPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
import ResetPassword from '@/pages/ResetPassword.vue';
import UpdateCustomer from '@/pages/UpdateCustomer.vue';
import LoansPage from '@/pages/LoansPage.vue';
import SavingsPage from '@/pages/SavingsPage.vue';
import GuarantorsPage from '@/pages/GuarantorsPage.vue';
import MarketPage from '@/pages/MarketPage.vue';
import VotingPage from '@/pages/VotingPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';
import MessagesPage from '@/pages/MessagesPage.vue';

const auth = (to, from, next) => {
  const token = JSON.parse(localStorage.getItem('login_user') || null);
  if (!token) {
    return next('/login');
  }
  return next();
};

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage,
  },
  {
    path: '/',
    name: 'DashboardPage',
    component: DashboardPage,
    beforeEnter: [auth],
  },
  {
    path: '/update-record',
    name: 'UpdateCustomer',
    component: UpdateCustomer,
    beforeEnter: [auth],
  },
  {
    path: '/loans',
    name: 'LoansPage',
    component: LoansPage,
    beforeEnter: [auth],
  },
  {
    path: '/savings',
    name: 'SavingsPage',
    component: SavingsPage,
    beforeEnter: [auth],
  },
  {
    path: '/guarantors',
    name: 'GuarantorsPage',
    component: GuarantorsPage,
    beforeEnter: [auth],
  },
  {
    path: '/marketplace',
    name: 'MarketPage',
    component: MarketPage,
    beforeEnter: [auth],
  },
  {
    path: '/voting',
    name: 'VotingPage',
    component: VotingPage,
    beforeEnter: [auth],
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    beforeEnter: [auth],
  },
  {
    path: '/messages',
    name: 'MessagesPage',
    component: MessagesPage,
    beforeEnter: [auth],
  },
];

export default routes;
