<template>
  <div class="lg:w-1/2 w-full bg-white py-2 px-4 rounded-lg shadow-lg">
    <canvas id="loansChart" class="!w-full"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
export default {
  name: 'LoansChart',
  mounted() {
    const ctx = document.getElementById('loansChart');

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['01', '02', '03', '04', '05', '06'],
        datasets: [
          {
            label: 'Loans',
            borderWidth: 1,
            borderColor: '#334DAA',
            backgroundColor: '#EDF0FF',
            data: [39, 54, 15, 2, 43, 78, 12],
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  },
};
</script>
<style lang=""></style>
