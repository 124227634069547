<template>
  <div class="hidden lg:block">
    <table class="w-full z-[9999999] border-[10px] border-white">
      <thead class="bg-[#F4F7FF]">
        <tr class="h-11">
          <th
            class="Work Sans normal h-[18px] w-[50px] pl-4 text-left text-[12px] font-[500] leading-[18px] text-[#667085]"
          >
            <input
              class="form-checkbox h-[20px] w-[20px] mt-1 cursor-pointer rounded-[6px] bg-[#F4F7FF] border-[#CCCCCC] text-[#2F4A89] outline-none focus:outline-none focus:ring-0"
              type="checkbox"
              id=""
              name="allSelect"
            />
          </th>
          <th
            v-for="item in tableHeader"
            :key="item.id"
            :class="[
              'text-start text-xs font-medium text-[#111111]',
              item.id === 1 && 'pl-2',
            ]"
          >
            <p class="text-[14px] font-bold">{{ item.name }}</p>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="tableData?.length === 0">
          <tr>
            <td colspan="10" class="text-center py-20">No data available</td>
          </tr>
        </template>
        <template v-if="loadingStatus === true">
          <LoadingState />
        </template>
        <template v-else>
          <tr
            v-for="data in tableData"
            :key="data.id"
            class="relative h-[48px] cursor-pointer border-b border-[#eaecf0] hover:bg-[#F4F7FF]"
          >
            <td class="pl-4">
              <input
                class="h-5 w-5 mt-1 rounded-[6px] border-b border-[#CCCCCC] bg-transparent bg-[#2F4A89] outline-none focus:outline-none focus:ring-0"
                type="checkbox"
                name="{el.uuid}"
              />
            </td>
            <td>
              <p class="text-sm text-[#101828] font-medium pl-2">
                LN/APP/000/{{ data.id }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-medium">
                {{ data.loan_product.name }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p class="text-sm text-[#101828] font-medium">
                {{ formatNumber(data.amount) }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p class="text-sm text-[#101828] font-medium">
                {{ data.month.name }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p class="text-sm text-[#101828] font-medium">
                {{ formatNumber(data.old_balance) }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p class="text-sm text-[#101828] font-medium">
                {{ formatNumber(data.new_balance) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-bold">
                {{ formatNumber(data.principal_bal) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-medium">
                {{ data.loan_product.duration }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-bold">
                {{ formatNumber(data.interest_bal) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-medium">
                {{ formatNumber(data.totalLoan) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-medium">
                {{ formatNumber(data.total_paid) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p class="text-sm text-[#101828] font-bold">
                {{ formatNumber(data.total_bal) }}
              </p>
            </td>
            <td v-if="tableType == 'loans'">
              <p
                :class="[
                  'text-sm text-[#666666] font-medium',
                  data.loan_status.name == 'Running'
                    ? 'text-blue-400'
                    : data.loan_status.name == 'Pending'
                    ? 'text-orange-400'
                    : '',
                ]"
              >
                {{ data.loan_status.name }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p
                :class="[
                  'text-sm text-[#666666] font-medium',
                  data.payment_status.name == 'Approved'
                    ? 'text-green-400'
                    : data.payment_status.name == 'Pending'
                    ? 'text-orange-400'
                    : 'text-gray-400',
                ]"
              >
                {{ data.payment_status.name }}
              </p>
            </td>
            <td v-if="tableType == 'repayments'">
              <p class="text-sm text-[#101828] font-medium">
                {{ data.payment_date }}
              </p>
            </td>
            <td>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="7.99984" cy="3.33333" r="1.33333" fill="#888888" />
                <circle cx="7.99984" cy="8.00033" r="1.33333" fill="#888888" />
                <ellipse
                  cx="7.99984"
                  cy="12.6663"
                  rx="1.33333"
                  ry="1.33333"
                  fill="#888888"
                />
              </svg>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <!-- Mobile Responsive -->
  <div class="block lg:hidden my-4 mb-10">
    <div v-if="tableData?.length === 0">
      <div>
        <p class="text-center py-20 font-bold">No data available</p>
      </div>
    </div>
    <div
      class="w-full h-full flex justify-center py-40"
      v-if="loadingStatus === true"
    >
      <div class="lds-hourglass"></div>
    </div>
    <div
      v-else
      v-for="data in tableData"
      :key="data.id"
      class="w-full p-4 bg-[#FFFFFF] border border-[#F5F5F5] my-3 rounded-lg"
    >
    <!-- loans -->
      <div v-if="tableType == 'loans'">
        <div class="flex justify-between items-center py-1">
          <p>LN/APP/000/{{ data.id }}</p>
          <p class="text-[#444444] text-sm font-bold">
            ₦{{ formatNumber(data.principal_bal) }}
          </p>
        </div>
        <div class="flex justify-between items-center py-1">
          <p class="text-[#444444] text-[16px] font-medium">
            {{ data.loan_product.name }}
          </p>
          <p class="text-green-500 text-sm font-bold">
            +{{ formatNumber(data.interest_bal) }}
          </p>
        </div>
        <div class="flex justify-between items-center py-1">
          <!-- <p class="text-[#444444] text-sm font-medium">{{ data.loan_product.duration }}</p> -->
          <p
            :class="[
              'px-3 py-1 rounded-md text-[13px]',
              data.loan_status.name == 'Running'
                ? 'text-blue-400 bg-blue-50'
                : data.loan_status.name == 'Pending'
                ? 'text-orange-400 bg-orange-50'
                : '',
            ]"
          >
            {{ data.loan_status.name }}
          </p>
          <p class="text-[#444444] text-sm font-bold">
            ₦{{ formatNumber(data.total_bal) }}
          </p>
        </div>
      </div>
      <!-- repayments -->
      <div v-if="tableType == 'repayments'">
        <div class="flex justify-between items-center py-1">
          <p>LN/APP/000/{{ data.id }}</p>
          <p class="text-[#444444] text-sm font-bold">
            ₦{{ formatNumber(data.old_balance) }}
          </p>
        </div>
        <div class="flex justify-between items-center py-1">
          <p class="text-[#444444] text-[16px] font-medium">
            {{ data.payment_date}}
          </p>
          <p class="text-red-500 text-sm font-bold">
            +₦{{ formatNumber(data.new_balance) }}
          </p>
        </div>
        <div class="flex justify-between items-center py-1">
          <p
            :class="[
              'px-3 py-1 rounded-md text-[13px]',
              data.payment_status.name == 'Approved'
                ? 'text-green-400 bg-green-50'
                : data.payment_status.name == 'Pending'
                ? 'text-orange-400 bg-orange-50'
                : 'text-gray-400 bg-gray-50',
            ]"
          >
            {{ data.payment_status.name }}
          </p>
          <p class="text-[#444444] text-sm font-bold">
            ₦{{ formatNumber(data.amount) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatNumber, formatDays } from '@/helper/util';
import LoadingState from '../global/LoadingState.vue';
import { useLoanStore } from '@/stores/loan';

export default {
  name: 'LoanTable',
  components: { LoadingState },
  setup() {
    const loan_store = useLoanStore();
    return { loan_store };
  },
  props: {
    tableHeader: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    tableType: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log(this.tableType);
    console.log('data testing!!!', this.tableData);
  },
  computed: {
    loadingStatus() {
      return useLoanStore().loadingStatus;
    },
  },
  methods: {
    formatNumber(currency) {
      return formatNumber(currency);
    },
    formatDays(days) {
      return formatDays(days);
    },
  },
};
</script>
<style lang=""></style>
