<template>
  <div class="lg:w-1/2 w-full bg-white py-2 px-4 rounded-lg shadow-lg">
    <canvas id="savingsChart" class="!w-full"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
export default {
  name: 'SavingsChart',
  mounted() {
    const ctx = document.getElementById('savingsChart');

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['01', '02', '03', '04', '05', '06'],
        datasets: [
          {
            label: 'Savings',
            borderWidth: 1,
            backgroundColor: '#DEFBE7',
            borderColor: '#006A51',
            data: [12, 19, 3, 5, 2, 3],
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  },
};
</script>
<style lang=""></style>
