<template>
  <div>
    <PageHeader />
    <PageSidebar />
  </div>
</template>
<script>
import PageHeader from './PageHeader.vue';
import PageSidebar from './PageSidebar.vue';

export default {
  name: "PageLayout",
  components: { PageHeader, PageSidebar }
}
</script>
<style lang="" scoped>
  
</style>