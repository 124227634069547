<template>
  <div class="px-4 border-b py-3 cursor-pointer hover:bg-[#F4F7FF]">
    <div class="flex justify-between items-center">
      <p class="text-sm font-bold text-[#222222]">You</p>
      <p class="text-[11px] font-normal text-[#444444]">May 24, 2023 12:00PM</p>
    </div>
    <div class="flex gap-2 py-2">
      <div class="flex gap-2 items-center py-1 px-2 border border-[#DFDFDF rounded-lg">
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70195 0H1.39995C0.739951 0 0.199951 0.54 0.199951 1.2V10.8C0.199951 11.46 0.739951 12 1.39995 12H8.59995C9.25995 12 9.79995 11.46 9.79995 10.8V4.098C9.79995 3.78 9.67395 3.474 9.44595 3.252L6.54795 0.354C6.32595 0.126 6.01995 0 5.70195 0ZM7.39995 7.8C7.39995 9.204 6.19395 10.326 4.76595 10.188C3.51795 10.068 2.59995 8.952 2.59995 7.698V4.584C2.59995 3.798 3.16395 3.084 3.94395 3.006C4.84395 2.916 5.59995 3.618 5.59995 4.5V7.2C5.59995 7.53 5.32995 7.8 4.99995 7.8C4.66995 7.8 4.39995 7.53 4.39995 7.2V4.5C4.39995 4.332 4.26795 4.2 4.09995 4.2C3.93195 4.2 3.79995 4.332 3.79995 4.5V7.734C3.79995 8.334 4.20795 8.886 4.79595 8.982C5.55195 9.108 6.19995 8.526 6.19995 7.8V6C6.19995 5.67 6.46995 5.4 6.79995 5.4C7.12995 5.4 7.39995 5.67 7.39995 6V7.8ZM6.19995 3V1.2L8.59995 3.6H6.79995C6.46995 3.6 6.19995 3.33 6.19995 3Z"
            fill="#2F4A89"
          />
        </svg>
        <span class="text-[11px] text-[#444444] font-normal">Document.pdf</span>
      </div>
      <div class="flex gap-2 items-center py-1 px-2 border border-[#DFDFDF rounded-lg">
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70195 0H1.39995C0.739951 0 0.199951 0.54 0.199951 1.2V10.8C0.199951 11.46 0.739951 12 1.39995 12H8.59995C9.25995 12 9.79995 11.46 9.79995 10.8V4.098C9.79995 3.78 9.67395 3.474 9.44595 3.252L6.54795 0.354C6.32595 0.126 6.01995 0 5.70195 0ZM7.39995 7.8C7.39995 9.204 6.19395 10.326 4.76595 10.188C3.51795 10.068 2.59995 8.952 2.59995 7.698V4.584C2.59995 3.798 3.16395 3.084 3.94395 3.006C4.84395 2.916 5.59995 3.618 5.59995 4.5V7.2C5.59995 7.53 5.32995 7.8 4.99995 7.8C4.66995 7.8 4.39995 7.53 4.39995 7.2V4.5C4.39995 4.332 4.26795 4.2 4.09995 4.2C3.93195 4.2 3.79995 4.332 3.79995 4.5V7.734C3.79995 8.334 4.20795 8.886 4.79595 8.982C5.55195 9.108 6.19995 8.526 6.19995 7.8V6C6.19995 5.67 6.46995 5.4 6.79995 5.4C7.12995 5.4 7.39995 5.67 7.39995 6V7.8ZM6.19995 3V1.2L8.59995 3.6H6.79995C6.46995 3.6 6.19995 3.33 6.19995 3Z"
            fill="#2F4A89"
          />
        </svg>
        <span class="text-[11px] text-[#444444] font-normal">Document.pdf</span>
      </div>
    </div>
    <div class="py-2 max-w-[83%]">
      <p class="text-xs text-[#333333] font-normal">Nelly Kim Furtado ComIH is a Canadian singer and songwriter. Furtado has sold over 40 million records worldwide making her one of the most successful Canadian artists. She is widely known for her musical versatility and genre experimentation.</p>
    </div>
  </div>
  <!-- Admin -->
  <div class="px-4 border-b py-3 cursor-pointer hover:bg-[#F4F7FF]">
    <div class="flex justify-between items-center">
      <p class="text-sm font-bold text-[#222222]">Admin</p>
      <p class="text-[11px] font-normal text-[#444444]">May 24, 2023 12:00PM</p>
    </div>
    <div class="flex gap-2 py-2">
      <div class="flex gap-2 items-center py-1 px-2 border border-[#DFDFDF rounded-lg">
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70195 0H1.39995C0.739951 0 0.199951 0.54 0.199951 1.2V10.8C0.199951 11.46 0.739951 12 1.39995 12H8.59995C9.25995 12 9.79995 11.46 9.79995 10.8V4.098C9.79995 3.78 9.67395 3.474 9.44595 3.252L6.54795 0.354C6.32595 0.126 6.01995 0 5.70195 0ZM7.39995 7.8C7.39995 9.204 6.19395 10.326 4.76595 10.188C3.51795 10.068 2.59995 8.952 2.59995 7.698V4.584C2.59995 3.798 3.16395 3.084 3.94395 3.006C4.84395 2.916 5.59995 3.618 5.59995 4.5V7.2C5.59995 7.53 5.32995 7.8 4.99995 7.8C4.66995 7.8 4.39995 7.53 4.39995 7.2V4.5C4.39995 4.332 4.26795 4.2 4.09995 4.2C3.93195 4.2 3.79995 4.332 3.79995 4.5V7.734C3.79995 8.334 4.20795 8.886 4.79595 8.982C5.55195 9.108 6.19995 8.526 6.19995 7.8V6C6.19995 5.67 6.46995 5.4 6.79995 5.4C7.12995 5.4 7.39995 5.67 7.39995 6V7.8ZM6.19995 3V1.2L8.59995 3.6H6.79995C6.46995 3.6 6.19995 3.33 6.19995 3Z"
            fill="#2F4A89"
          />
        </svg>
        <span class="text-[11px] text-[#444444] font-normal">Document.pdf</span>
      </div>
      <div class="flex gap-2 items-center py-1 px-2 border border-[#DFDFDF rounded-lg">
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.70195 0H1.39995C0.739951 0 0.199951 0.54 0.199951 1.2V10.8C0.199951 11.46 0.739951 12 1.39995 12H8.59995C9.25995 12 9.79995 11.46 9.79995 10.8V4.098C9.79995 3.78 9.67395 3.474 9.44595 3.252L6.54795 0.354C6.32595 0.126 6.01995 0 5.70195 0ZM7.39995 7.8C7.39995 9.204 6.19395 10.326 4.76595 10.188C3.51795 10.068 2.59995 8.952 2.59995 7.698V4.584C2.59995 3.798 3.16395 3.084 3.94395 3.006C4.84395 2.916 5.59995 3.618 5.59995 4.5V7.2C5.59995 7.53 5.32995 7.8 4.99995 7.8C4.66995 7.8 4.39995 7.53 4.39995 7.2V4.5C4.39995 4.332 4.26795 4.2 4.09995 4.2C3.93195 4.2 3.79995 4.332 3.79995 4.5V7.734C3.79995 8.334 4.20795 8.886 4.79595 8.982C5.55195 9.108 6.19995 8.526 6.19995 7.8V6C6.19995 5.67 6.46995 5.4 6.79995 5.4C7.12995 5.4 7.39995 5.67 7.39995 6V7.8ZM6.19995 3V1.2L8.59995 3.6H6.79995C6.46995 3.6 6.19995 3.33 6.19995 3Z"
            fill="#2F4A89"
          />
        </svg>
        <span class="text-[11px] text-[#444444] font-normal">Document.pdf</span>
      </div>
    </div>
    <div class="py-2 max-w-[83%]">
      <p class="text-xs text-[#333333] font-normal">Nelly Kim Furtado ComIH is a Canadian singer and songwriter. Furtado has sold over 40 million records worldwide making her one of the most successful Canadian artists. She is widely known for her musical versatility and genre experimentation.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MessageDetails',
};
</script>
<style lang=""></style>
