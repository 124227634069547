<template>
  <div class="">
    <div
      class="background-image-container flex justify-center items-center overflow-hidden"
    >
      <div
        class="bg-white w-[370px] sm:w-[440px] p-2 rounded-2xl flex gap-2 relative"
      >
      <!-- Reset Password -->
        <div
          v-if="view === 'resetPassword'"
          class="px-4 sm:px-6 mx-auto py-6 w-[412px]"
        >
          <div class="pb-6">
            <p class="text-[#222222] font-bold text-2xl pb-1 tracking-wide">
              Password reset
            </p>
            <p class="text-[#888888] text-sm font-normal">
              Enter your email to reset your password
            </p>
          </div>
          <!-- Form -->
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="flex flex-col w-full pb-8">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Email address</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.email }"
                type="email"
                name="email"
                placeholder="Enter email address"
              />
              <span class="text-red-500 text-xs">{{ errors.email }}</span>
            </div>
            <div class="text-white pb-6">
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full"
              >
                <span v-if="user_store.loadingStatus"> Loading... </span>
                <span v-else>Send OTP</span>
              </button>
            </div>
            <div class="text-[#2F4A89] text-xs font-normal">
              <span @click="$router.push('/login')" class="cursor-pointer"
                >Log in instead</span
              >
            </div>
          </Form>
        </div>
        <!-- Enter OTP -->
        <div
          v-if="view === 'enterOTP'"
          class="px-4 sm:px-6 mx-auto py-6 w-[412px]"
        >
          <div class="pb-6">
            <p class="text-[#222222] font-bold text-2xl pb-1 tracking-wide">
              Enter OTP
            </p>
            <p class="text-[#888888] text-sm font-normal">
              Enter the OTP that was sent to your inbox to proceed
            </p>
          </div>
          <!-- Form -->
          <Form
            @submit="submitOtp"
            :validation-schema="otpSchema"
            v-slot="{ errors }"
          >
            <div class="flex flex-col w-full pb-8">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >OTP</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.otp }"
                type="text"
                name="otp"
                placeholder="Enter OTP"
              />
              <span class="text-red-500 text-xs">{{ errors.otp }}</span>
            </div>
            <div class="text-white pb-6">
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full"
              >
                <span v-if="user_store.loadingStatus"> Loading... </span>
                <span v-else>Reset password</span>
              </button>
            </div>
            <div class="text-[#2F4A89] text-xs font-normal">
              <span @click="$router.push('/login')" class="cursor-pointer"
                >Log in instead</span
              >
            </div>
          </Form>
        </div>
        <!-- New password -->
        <div
          v-if="view === 'newPassword'"
          class="px-4 sm:px-6 mx-auto py-6 w-[412px]"
        >
          <div class="pb-6">
            <p class="text-[#222222] font-bold text-2xl pb-1 tracking-wide">
              Password reset
            </p>
            <p class="text-[#888888] text-sm font-normal">
              Enter your email to reset your password
            </p>
          </div>
          <!-- Form -->
          <Form
            @submit="submitNewPassword"
            :validation-schema="newPassword"
            v-slot="{ errors }"
          >
            <div class="flex flex-col w-full pb-5">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Password</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.password }"
                type="password"
                name="password"
                placeholder="Enter password"
              />
              <span class="text-red-500 text-xs">{{ errors.password }}</span>
            </div>
            <div class="flex flex-col w-full pb-5">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Confirm password</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.confirmPassword }"
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              <span class="text-red-500 text-xs">{{ errors.confirmPassword }}</span>
            </div>
            <div class="text-white pb-6">
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full"
              >
                <span v-if="user_store.loadingStatus"> Loading... </span>
                <span v-else>Save new password</span>
              </button>
            </div>
            <div class="text-[#2F4A89] text-xs font-normal">
              <span @click="$router.push('/login')" class="cursor-pointer"
                >Log in instead</span
              >
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';

export default {
  name: 'ResetPassword',
  components: {
    Field,
    Form,
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('Enter a valid email address')
        .email('Email is invalid'),
    });
    const otpSchema = Yup.object().shape({
      otp: Yup.string()
        .required('OTP field cannot be empty'),
    });
    const newPassword = Yup.object().shape({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .required('Password does not match'),
    });
    const user_store = useUserStore();
    return { user_store, schema, otpSchema, newPassword };
  },
  data() {
    return {
      view: 'resetPassword',
      passwordResetId: null,
    };
  },
  created() {},

  methods: {
    onSubmit(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        email: values.email,
      };
      this.user_store
        .resetUserPassword(payload)
        .then((res) => {
          console.log(res);
          if (res.status == true) {
            this.user_store.setLoading(false);
            toast.success('OTP has been sent to your mailbox');
            localStorage.setItem('email', JSON.stringify(payload.email));
            console.log(localStorage.getItem('email'))
            this.view = 'enterOTP';
          } else {
            this.user_store.setLoading(false);
            toast.error('The given data was invalid.');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('The given data was invalid.');
          console.log(error);
        });
    },

    clearEmail() {
      localStorage.removeItem('email');
    },

    submitOtp(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        email: JSON.parse(localStorage.getItem('email')),
        otp: values.otp,
      };
      console.log(payload);
      this.user_store
        .verifyUserOTP(payload)
        .then((res) => {
          console.log(res);
          if (res.status == true) {
            this.user_store.setLoading(false);
            this.passwordResetId = res.data;
            toast.success('You can now set a new password');
            this.view = 'newPassword';
            this.clearEmail();
          } else {
            this.user_store.setLoading(false);
            toast.error('The given data was invalid.');
            this.clearEmail();
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('The given data was invalid.');
          console.log(error);
          this.clearEmail();
        });
    },

    submitNewPassword(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        password: values.password,
        confirmPassword: values.confirmPassword
      };
      this.user_store
        .setNewPassword(this.passwordResetId, payload)
        .then((res) => {
          console.log(res);
          if (res.status == true) {
            this.user_store.setLoading(false);
            toast.success('Login to continue');
            this.$router.push('/login');
          } else {
            this.user_store.setLoading(false);
            toast.error('The given data was invalid.');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('Unable to reset password, Try agian.');
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.background-image-container {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/img/bg.png');
  background-size: cover;
  background-position: center;
  background-color: #111111;
}
</style>
