<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="auto">
      <div
        class="bg-[#FFFFFF] flex flex-col items-center justify-center p-6 lg:w-[388px] sm:w-[388px] w-[300px] rounded-[10px]"
      >
        <svg
          width="48"
          height="49"
          viewBox="0 0 48 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6666 40.0556C10.6666 42.5 12.6666 44.5 15.111 44.5H32.8888C35.3332 44.5 37.3332 42.5 37.3332 40.0556V13.3889H10.6666V40.0556ZM17.711 25.8111C16.8443 24.9444 16.8443 23.5444 17.711 22.6778C18.5777 21.8111 19.9777 21.8111 20.8443 22.6778L23.9999 25.8111L27.1332 22.6778C27.9999 21.8111 29.3999 21.8111 30.2666 22.6778C31.1332 23.5444 31.1332 24.9444 30.2666 25.8111L27.1332 28.9444L30.2666 32.0778C31.1332 32.9444 31.1332 34.3444 30.2666 35.2111C29.3999 36.0778 27.9999 36.0778 27.1332 35.2111L23.9999 32.0778L20.8666 35.2111C19.9999 36.0778 18.5999 36.0778 17.7332 35.2111C16.8666 34.3444 16.8666 32.9444 17.7332 32.0778L20.8666 28.9444L17.711 25.8111ZM37.3332 6.72222H31.7777L30.1999 5.14444C29.7999 4.74444 29.2221 4.5 28.6443 4.5H19.3554C18.7777 4.5 18.1999 4.74444 17.7999 5.14444L16.2221 6.72222H10.6666C9.44434 6.72222 8.44434 7.72222 8.44434 8.94444C8.44434 10.1667 9.44434 11.1667 10.6666 11.1667H37.3332C38.5554 11.1667 39.5554 10.1667 39.5554 8.94444C39.5554 7.72222 38.5554 6.72222 37.3332 6.72222Z"
            fill="#666666"
          />
        </svg>

        <div class="text-center pt-5">
          <p class="text-[#444444] text-[16px] font-semibold pb-2">
            Are you sure you want to delete this guarantor?
          </p>
          <p class="text-[#444444] text-sm font-medium">
            You cannot undo this action
          </p>
          <div class="pt-5 flex justify-between gap-5">
            <button
              class="bg-[#FAFAFA] border border-[#F5F5F5] py-2 px-4 lg:w-[160px] sm:w-[160px] w-[120px] rounded-md text-[#222222] text-sm"
              @click="dismiss"
            >
              Cancel
            </button>
            <button
              class="bg-[#F21759] border border-[#F5F5F5] py-2 px-4 lg:w-[160px] sm:w-[160px] w-[120px] rounded-md text-[#FFFFFF] text-sm"
              @click="deleteAction"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      dialog: true,
    };
  },
  mounted() {
    this.dialog = true;
  },
  watch: {
    dialog: {
      immediate: true,
      handler() {
        this.dialog = true;
      },
    },
  },
  methods: {
    dismiss() {
      this.dialog = false;
      this.$emit('dismiss');
    },
    deleteAction() {
      this.$emit('deleteAction', this.dialog);
    },
  },
};
</script>
