<template>
  <div>
    <PageLayout />
    <div
      class="w-full bg-[#f8f9fd] h-screen overflow-x-hidden lg:mb-0 sm:mb-0 hidden lg:block sm:block"
    >
      <div class="pt-20 lg:pl-[16%]">
        <div class="px-4">
          <p class="text-sm font-medium">Profile</p>
          <div class="bg-white my-2 rounded-lg flex">
            <!-- Settings Nav -->
            <div class="w-[185px] h-[85vh] border-r">
              <div class="py-4">
                <div
                  :class="[
                    'py-4 px-3 cursor-pointer',
                    isActive === 'profile' ? 'bg-[#F4F7FF] font-medium' : '',
                  ]"
                  @click="isActive = 'profile'"
                >
                  <p class="text-xs text-[#222222]">Edit profile</p>
                </div>
                <div
                  :class="[
                    'py-4 px-3 cursor-pointer',
                    isActive === 'password' ? 'bg-[#F4F7FF] font-medium' : '',
                  ]"
                  @click="isActive = 'password'"
                >
                  <p class="text-xs text-[#222222]">Change password</p>
                </div>
                <!-- <div
                  :class="[
                    'py-4 px-3 cursor-pointer',
                    isActive === 'support' ? 'bg-[#F4F7FF] font-medium' : '',
                  ]"
                  @click="isActive = 'support'"
                >
                  <p class="text-xs text-[#222222]">Contact support</p>
                </div> -->
              </div>
            </div>

            <!-- Main -->
            <div class="mx-auto py-6">
              <div v-if="isActive === 'profile'">
                <ChangeProfile />
              </div>
              <div v-if="isActive === 'password'">
                <ChangePassword />
              </div>
              <!-- <div v-if="isActive === 'support'">
                <ContactSupport />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="bg-[#f8f9fd] block lg:hidden sm:hidden w-full h-screen">
      <div class="pt-20 px-4">
        <div v-if="isActive === 'profile'">
          <div class="flex justify-between items-center">
            <p class="text-[#222222] text-[16px] font-medium">Profile</p>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7291 10.3182H10.9881L10.7255 10.065C11.851 8.75184 12.4326 6.96032 12.1137 5.05624C11.6728 2.44869 9.49674 0.366395 6.87043 0.0474853C2.90282 -0.440259 -0.436353 2.89891 0.0513915 6.86652C0.370301 9.49284 2.45259 11.6689 5.06015 12.1098C6.96423 12.4287 8.75575 11.8471 10.0689 10.7216L10.3222 10.9842V11.7252L14.3085 15.7116C14.6931 16.0961 15.3215 16.0961 15.7061 15.7116C16.0907 15.327 16.0907 14.6986 15.7061 14.314L11.7291 10.3182ZM6.10129 10.3182C3.76575 10.3182 1.88043 8.43293 1.88043 6.09739C1.88043 3.76184 3.76575 1.87653 6.10129 1.87653C8.43684 1.87653 10.3222 3.76184 10.3222 6.09739C10.3222 8.43293 8.43684 10.3182 6.10129 10.3182Z"
                fill="#666666"
              />
            </svg>
          </div>
          <div
            class="flex flex-col items-center justify-center h-[140px] bg-white border border-[#F5F5F5] rounded-lg mt-4"
          >
            <div>
              <div v-if="user_store?.userProfile?.image_path" class="w-14 h-14">
                <img
                  :src="user_store?.userProfile?.image_path"
                  alt="profile image"
                  class="w-full h-full rounded-full"
                />
              </div>
              <svg
                v-else
                width="50"
                height="50"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.55 13.8 7.68 13 10 13C12.32 13 14.45 13.8 16.14 15.12C14.43 17.18 12.03 18 10 18Z"
                  fill="#5B6C93"
                />
              </svg>
            </div>
            <div class="pt-2 text-center">
              <p class="text-[#222222] text-[14px] font-bold">
                {{ user_store?.userProfile?.firstName }}
                {{ user_store?.userProfile?.lastName }}
              </p>
              <p class="text-[#444444] text-[14px] font-normal">Member</p>
            </div>
          </div>
          <div
            class="flex items-center h-[72px] px-5 bg-white border border-[#F5F5F5] rounded-lg mt-4 cursor-pointer"
            @click="isActive = 'edit'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9191 4.71105L15.0791 2.0149C14.903 1.29486 14.279 0.798828 13.551 0.798828H2.43834C1.7183 0.798828 1.08627 1.30286 0.918257 2.0149L0.0782091 4.71105C-0.289812 6.28714 0.758248 7.19919 0.79825 7.24719V13.5996C0.79825 14.4796 1.51829 15.1996 2.39834 15.1996H13.599C14.479 15.1996 15.1991 14.4796 15.1991 13.5996V7.24719C16.0951 6.35114 16.0711 5.31908 15.9191 4.71105ZM8.7987 2.39892H10.3668L10.7988 5.21508C10.8708 5.78311 10.4868 6.39915 9.77476 6.39915C9.23873 6.39915 8.7987 5.92712 8.7987 5.35109V2.39892ZM3.55041 5.48709C3.4864 6.00712 3.07038 6.39915 2.58235 6.39915C1.83831 6.39915 1.50229 5.6231 1.6303 5.08707L2.43834 2.39892H4.01443L3.55041 5.48709ZM7.19861 5.35109C7.19861 5.92712 6.75859 6.39915 6.16655 6.39915C5.56652 6.39915 5.1265 5.83911 5.1905 5.21508L5.63052 2.39892H7.19861V5.35109ZM13.415 6.39915C12.9269 6.39915 12.5029 6.00712 12.4469 5.48709L11.9829 2.39892L13.527 2.39092L14.367 5.08707C14.495 5.6231 14.167 6.39915 13.415 6.39915Z"
                fill="#506CC0"
                fill-opacity="0.75"
              />
            </svg>
            <p class="px-3 text-[#222222] text-[16px]">Edit profile</p>
          </div>
          <div
            class="flex items-center h-[72px] px-5 bg-white border border-[#F5F5F5] rounded-lg mt-4 cursor-pointer"
            @click="isActive = 'password'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM4.44444 12.4444C3.95556 12.4444 3.55556 12.0444 3.55556 11.5556V7.11111C3.55556 6.62222 3.95556 6.22222 4.44444 6.22222C4.93333 6.22222 5.33333 6.62222 5.33333 7.11111V11.5556C5.33333 12.0444 4.93333 12.4444 4.44444 12.4444ZM8 12.4444C7.51111 12.4444 7.11111 12.0444 7.11111 11.5556V4.44444C7.11111 3.95556 7.51111 3.55556 8 3.55556C8.48889 3.55556 8.88889 3.95556 8.88889 4.44444V11.5556C8.88889 12.0444 8.48889 12.4444 8 12.4444ZM11.5556 12.4444C11.0667 12.4444 10.6667 12.0444 10.6667 11.5556V9.77778C10.6667 9.28889 11.0667 8.88889 11.5556 8.88889C12.0444 8.88889 12.4444 9.28889 12.4444 9.77778V11.5556C12.4444 12.0444 12.0444 12.4444 11.5556 12.4444Z"
                fill="#506CC0"
                fill-opacity="0.75"
              />
            </svg>
            <p class="px-3 text-[#222222] text-[16px]">Change password</p>
          </div>
          <!-- <div
            class="flex items-center h-[72px] px-5 bg-white border border-[#F5F5F5] rounded-lg mt-4 cursor-pointer"
            @click="isActive = 'support'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM4.44444 12.4444C3.95556 12.4444 3.55556 12.0444 3.55556 11.5556V7.11111C3.55556 6.62222 3.95556 6.22222 4.44444 6.22222C4.93333 6.22222 5.33333 6.62222 5.33333 7.11111V11.5556C5.33333 12.0444 4.93333 12.4444 4.44444 12.4444ZM8 12.4444C7.51111 12.4444 7.11111 12.0444 7.11111 11.5556V4.44444C7.11111 3.95556 7.51111 3.55556 8 3.55556C8.48889 3.55556 8.88889 3.95556 8.88889 4.44444V11.5556C8.88889 12.0444 8.48889 12.4444 8 12.4444ZM11.5556 12.4444C11.0667 12.4444 10.6667 12.0444 10.6667 11.5556V9.77778C10.6667 9.28889 11.0667 8.88889 11.5556 8.88889C12.0444 8.88889 12.4444 9.28889 12.4444 9.77778V11.5556C12.4444 12.0444 12.0444 12.4444 11.5556 12.4444Z"
                fill="#506CC0"
                fill-opacity="0.75"
              />
            </svg>
            <p class="px-3 text-[#222222] text-[16px]">Contact support</p>
          </div> -->
          <div
            class="flex items-center h-[72px] px-5 bg-white border border-[#F5F5F5] rounded-lg mt-4 cursor-pointer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2222 0H1.77778C0.8 0 0 0.8 0 1.77778V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V1.77778C16 0.8 15.2 0 14.2222 0ZM4.44444 12.4444C3.95556 12.4444 3.55556 12.0444 3.55556 11.5556V7.11111C3.55556 6.62222 3.95556 6.22222 4.44444 6.22222C4.93333 6.22222 5.33333 6.62222 5.33333 7.11111V11.5556C5.33333 12.0444 4.93333 12.4444 4.44444 12.4444ZM8 12.4444C7.51111 12.4444 7.11111 12.0444 7.11111 11.5556V4.44444C7.11111 3.95556 7.51111 3.55556 8 3.55556C8.48889 3.55556 8.88889 3.95556 8.88889 4.44444V11.5556C8.88889 12.0444 8.48889 12.4444 8 12.4444ZM11.5556 12.4444C11.0667 12.4444 10.6667 12.0444 10.6667 11.5556V9.77778C10.6667 9.28889 11.0667 8.88889 11.5556 8.88889C12.0444 8.88889 12.4444 9.28889 12.4444 9.77778V11.5556C12.4444 12.0444 12.0444 12.4444 11.5556 12.4444Z"
                fill="#506CC0"
                fill-opacity="0.75"
              />
            </svg>
            <p @click="logoutUser()" class="px-3 text-[#222222] text-[16px]">
              Log out
            </p>
          </div>
        </div>
        <!-- edit -->
        <div v-if="isActive === 'edit'">
          <ChangeProfile @back="goBack" />
        </div>
        <!-- password -->
        <div v-if="isActive === 'password'">
          <ChangePassword @back="goBack" />
        </div>
        <!-- support -->
        <!-- <div v-if="isActive === 'support'">
          <ContactSupport @back="goBack" />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import ChangeProfile from '@/components/ChangeProfile.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import { useUserStore } from '@/stores/user';

export default {
  name: 'ProfilePage',
  components: { PageLayout, ChangeProfile, ChangePassword },
  setup() {
    const user_store = useUserStore();
    return { user_store };
  },
  data() {
    return {
      isActive: 'profile',
    };
  },
  methods: {
    goBack() {
      this.isActive = 'profile';
    },
    logoutUser() {
      this.user_store.logout();
      this.$router.push('/login');
    },
  },
};
</script>
<style lang=""></style>
