<template>
  <tr class="mx-auto w-full animate-pulse rounded-md p-4 shadow">
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-4">
      <p class="h-10  space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
    <td class="space-y-10 py-1">
      <p class="h-10 w-full space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
      <p class="h-10 space-y-3 rounded bg-slate-100"></p>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'LoadingState',
};
</script>
<style lang=""></style>
