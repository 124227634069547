<template>
  <div>
    <PageLayout />
    <div
      class="w-full min-h-screen bg-[#f8f9fd] overflow-x-hidden lg:mb-0 sm:mb-0"
    >
      <div class="py-20 lg:pl-[16%]">
        <div class="px-4">
          <p class="text-sm font-medium">Dashboard</p>
          <div class="block sm:hidden lg:hidden text-center py-6 font-medium">
            <p class="text-[#444444] text-sm font-medium">SAVINGS</p>
            <p class="text-[#222222] text-2xl font-bold">
              {{
                formatCurrency(
                  user_store?.userProfile?.member_account?.savingBal
                )
              }}
            </p>
          </div>
          <div class="py-3 flex justify-between w-full">
            <CardComponent
              bgColor="#EDF0FF"
              :image="LoansIcon"
              cardTitle="Loans"
              textColor="#334DAA"
              :subTotal="
                formatCurrency(
                  user_store?.userProfile?.member_account?.loanBal
                    ? user_store?.userProfile?.member_account?.loanBal
                    : 0
                )
              "
            />
            <CardComponent
              bgColor="#DEFBE7"
              :image="SavingsIcon"
              cardTitle="Savings"
              textColor="#006A51"
              :subTotal="
                formatCurrency(
                  user_store?.userProfile?.member_account?.savingBal
                    ? user_store?.userProfile?.member_account?.savingBal
                    : 0
                )
              "
              class="hidden sm:block lg:block"
            />
            <CardComponent
              bgColor="#FFDAEC"
              :image="GuarantorIcon"
              cardTitle="Guarantor"
              textColor="#C52371"
              :subTotal="
                user_store?.userProfile?.member_account?.guarantorGrant.slice(
                  0,
                  4
                )
              "
            />
            <CardComponent
              bgColor="#D8E0FF"
              :image="GuarantorIcon"
              cardTitle="Messages"
              textColor="#000000"
              :subTotal="
                user_store?.userProfile?.member_account?.guarantorGrant.slice(
                  0,
                  4
                )
              "
              class="hidden sm:block lg:block"
            />
          </div>
          <div
            class="w-full h-full flex flex-col lg:flex-row justify-between py-2 gap-5"
          >
            <LoansChart />
            <SavingsChart />
          </div>
          <div class="py-3">
            <p class="text-sm font-semibold text-[#444444]">
              Guarantor requests
            </p>
            <div
              class="text-center lg:mt-10 lg:pb-0 pt-5 pb-5"
              v-if="
                guarantor_store?.dashboardIncomingGuarantors?.data?.length === 0
              "
            >
              <p class="lg:text-lg sm:text-lg text-sm font-bold text-[#2F4A89]">
                No Incoming Guarantor Requests
              </p>
            </div>
            <div
              v-else
              class="grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-2"
            >
              <div
                v-for="data in guarantor_store?.dashboardIncomingGuarantors
                  ?.data"
                :key="data.id"
                class="py-1"
              >
                <GuarantorRequestCard :cardData="data" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import CardComponent from '@/components/global/CardComponent.vue';
import LoansIcon from '@/assets/img/loans.png';
import SavingsIcon from '@/assets/img/saving.png';
import GuarantorIcon from '@/assets/img/guarantor.png';
import LoansChart from '@/components/LoansChart.vue';
import SavingsChart from '@/components/SavingsChart.vue';
import GuarantorRequestCard from '@/components/global/GuarantorRequestCard.vue';
import { useUserStore } from '@/stores/user';
import { useLoanStore } from '@/stores/loan';
import { useGuarantorStore } from '@/stores/guarantor';
import { formatCurrency } from '@/helper/util';

export default {
  name: 'DashboardPage',
  components: {
    PageLayout,
    CardComponent,
    LoansChart,
    SavingsChart,
    GuarantorRequestCard,
  },
  setup() {
    const user_store = useUserStore();
    const loan_store = useLoanStore();
    const guarantor_store = useGuarantorStore();
    return { user_store, loan_store, guarantor_store };
  },
  data() {
    return {
      LoansIcon: LoansIcon,
      SavingsIcon: SavingsIcon,
      GuarantorIcon: GuarantorIcon,
    };
  },
  mounted() {
    this.user_store.userDetails().catch((error) => {
      console.log(error);
    });
    this.loan_store.fetchLoanRecords().catch((error) => {
      console.log(error);
    });
    this.guarantor_store.fetchDashboardIncomingGuarantor().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    formatCurrency(currency) {
      return formatCurrency(currency);
    },
  },
};
</script>
<style lang=""></style>
