import { defineStore } from 'pinia';
import userService from '@/helper/services/api';

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    user: null,
    companyProfile: {},
    loading: false,
    userProfile: {},
    agencies: [],
    inbox: [],
    outbox: [],
  }),
  getters: {
    loadingStatus: (state) => state.loading,
  },
  actions: {
    setLoading(status) {
      this.loading = status;
    },
    async loginUser(payload) {
      try {
        const res = await userService.loginUser(payload);
        this.user = res.data;
        localStorage.setItem('login_user', JSON.stringify(res.data));
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async resetUserPassword(payload) {
      try {
        const res = await userService.resetUserPassword(payload);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async verifyUserOTP(payload) {
      try {
        const res = await userService.verifyUserOTP(payload);
        console.log('this is the user state', res);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async setNewPassword(id, payload) {
      try {
        const res = await userService.setNewPassword(id, payload);
        console.log('user state for set new user', res);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async registerUser(payload) {
      try {
        const res = await userService.registerUser(payload);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async companyDetails() {
      try {
        const { data } = await userService.getCompanyProfile();
        this.companyProfile = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },

    async userDetails() {
      try {
        const { data } = await userService.getUserProfile();
        console.log('user profile!!!', data);
        this.userProfile = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateUserProfile(payload) {
      try {
        const res = await userService.updateUserProfile(payload);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async changeUserPassword(payload) {
      try {
        const res = await userService.changeUserPassword(payload);
        console.log('changeUserPassword', res);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async composeOrReplyMessage(payload) {
      try {
        const res = await userService.composeOrReplyMessage(payload);
        console.log('composeOrReplyMessage', res);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
    async getInbox() {
      try {
        const { data } = await userService.getInbox();
        console.log('inbox', data);
        this.inbox = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async getOutbox() {
      try {
        const { data } = await userService.getOutbox();
        console.log('outbox', data);
        this.outbox = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAgency() {
      try {
        const { data } = await userService.getAgency();
        this.agencies = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      localStorage.removeItem('login_user');
      this.user = null;
    },
  },
});
