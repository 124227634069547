import axios from 'axios';

let bearerToken = '';

try {
  const loginData = JSON.parse(localStorage.getItem('login_user'));
  if (loginData && loginData.token) {
    bearerToken = loginData.token;
  }
} catch (error) {
  console.error('Error parsing login data from localStorage:', error);
}

const apiClient = axios.create({
  baseURL: 'https://portal.fodalcoop.com/api/v2',
  withCredentials: false,
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});

console.log('util', bearerToken);

export { apiClient as http };

export const formatCurrency = (value) => {
  return `₦${parseFloat(value).toLocaleString()}`;
};

export const formatNumber = (value) => {
  return `${parseFloat(value).toLocaleString()}`;
};

export const formatDays = (days) => {
  return `${days / 365.25}`;
};

export const routeList = [
  {
    path: '/',
    name: 'Dashboard',
  },
  {
    path: '/loans',
    name: 'Loans',
  },
  {
    path: '/savings',
    name: 'Savings',
  },
  {
    path: '/guarantors',
    name: 'Guarantors',
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
  },
  {
    path: '/voting',
    name: 'Voting',
  },
];

export const mobileRouteList = [
  {
    path: '/',
    name: 'Dashboard',
  },
  {
    path: '/loans',
    name: 'Loans',
  },
  {
    path: '/savings',
    name: 'Savings',
  },
  {
    path: '/guarantors',
    name: 'Guarantors',
  },
];
