<template>
  <div
    class="lg:w-[495px] sm:w-[495px] w-[360px] lg:p-5 sm:p-5 rounded-[10px] lg:border sm:border border-[#F5F5F5]"
  >
    <div>
      <div class="block lg:hidden sm:hidden">
        <div class="flex items-center">
          <svg
            @click="$emit('back')"
            class="cursor-pointer"
            width="18"
            height="18"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9173 6.50016H1.58398M1.58398 6.50016L6.25065 11.1668M1.58398 6.50016L6.25065 1.8335"
              stroke="#334DAA"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="text-[#222222] text-[16px] font-bold py-4 px-2">
            Edit profile
          </p>
        </div>
      </div>
      <p class="text-[#444444] text-[11px]">Membership ID</p>
      <p class="text-[#222222] font-bold text-[16px]">001</p>
    </div>
    <form class="pt-3" @submit.prevent="onSubmit">
      <div v-if="view === 'firstStage'">
        <div class="flex gap-3">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >First name</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="John"
              v-model="user_store.userProfile.firstName"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Last name</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="Doe"
              v-model="user_store.userProfile.lastName"
            />
          </div>
        </div>
        <div class="flex flex-row gap-3">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Phone number</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="0812 345 6789"
              v-model="user_store.userProfile.phone"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >NIN</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="Enter NIN number"
              v-model="user_store.userProfile.nin"
            />
          </div>
        </div>

        <div class="flex flex-col w-full pb-4 relative">
          <label class="text-[#222222] text-[13px] font-medium pb-1"
            >Agency</label
          >
          <div class="absolute right-2 top-8">
            <img src="@/assets/img/inputMore.svg" alt="more" />
          </div>
          <select
            class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
            name="agency"
            id="agency"
            placeholder="choose an agency"
            v-model="user_store.userProfile.agency_id"
          >
            <option value="" disabled>Select your agency</option>
            <option
              v-for="item in user_store?.agencies"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Contact address</label
            >
            <textarea
              class="border border-[#F5F5F5] rounded-md px-4 py-2 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="12, Anywhere Street, Idi-Iroko, Lagos"
              v-model="user_store.userProfile.residentialAddress"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Office address</label
            >
            <textarea
              class="border border-[#F5F5F5] rounded-md px-4 py-2 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="12, Anywhere Street, Idi-Iroko, Lagos"
              v-model="user_store.userProfile.officeAddress"
            />
          </div>
        </div>
        <div class="text-white pt-4 pb-2">
          <button
            @click="view = 'secondStage'"
            class="bg-[#2F4A89] rounded-md h-[40px] w-full"
          >
            <span>Next</span>
          </button>
        </div>
      </div>

      <div v-if="view === 'secondStage'">
        <div class="flex gap-3">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Date of birth</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="date"
              v-model="user_store.userProfile.dob"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Date of appointment</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="date"
              v-model="user_store.userProfile.doe"
            />
          </div>
        </div>
        <div class="flex gap-3">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Next of kin name</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="Full name of next of kin"
              v-model="user_store.userProfile.nokName"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Next of kin telephone</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="08011223344"
              v-model="user_store.userProfile.nokTel"
            />
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Relationship with next of kin</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="How is your next of kin related to you?"
              v-model="user_store.userProfile.nokRel"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Next of kin address</label
            >
            <input
              class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="address"
              placeholder="where is your next of kin address"
              v-model="user_store.userProfile.nokAddress"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Upload passport</label
            >
            <div
              class="flex border px-3 py-4 bg-[#fbfcfc] border-dashed border-[#2F4A89] rounded-md"
            >
              <input
                class="absolute left-[-100%] h-[48px] w-[200%] text-xs cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="file"
                @change="upload($event)"
              />
              <div class="flex items-center">
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="38"
                    height="38"
                    rx="19"
                    fill="#006A51"
                    fill-opacity="0.1"
                  />
                  <path
                    d="M28 26V12C28 10.9 27.1 10 26 10H12C10.9 10 10 10.9 10 12V26C10 27.1 10.9 28 12 28H26C27.1 28 28 27.1 28 26ZM15.9 20.98L18 23.51L21.1 19.52C21.3 19.26 21.7 19.26 21.9 19.53L25.41 24.21C25.66 24.54 25.42 25.01 25.01 25.01H13.02C12.6 25.01 12.37 24.53 12.63 24.2L15.12 21C15.31 20.74 15.69 20.73 15.9 20.98Z"
                    fill="#2F4A89"
                  />
                </svg>
                <div
                  v-if="user_store.userProfile.image_path === ''"
                  class="text-xs pl-5 leading-5 px-6"
                >
                  <p class="text-[#222222]">Select an image to upload or</p>
                  <p class="font-normal text-[#2F4A89]">click to browse</p>
                </div>
                <div v-else class="text-xs pl-5 leading-5 px-6 pr-[40px]">
                  <p class="text-[#222222]">
                    {{
                      user_store.userProfile.image_path.substring(0, 50) + '...'
                    }}
                  </p>
                  <p class="font-normal text-[#2F4A89]">Remove file</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 pb-2 flex gap-2">
          <button
            @click="view = 'firstStage'"
            class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
          >
            <span v-if="user_store.loadingStatus">Loading...</span>
            <span v-else>Back</span>
          </button>
          <button
            type="submit"
            class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
          >
            <span>Update Profile</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
export default {
  name: 'ChangeProfile',
  setup() {
    const user_store = useUserStore();
    return { user_store };
  },
  data() {
    return {
      view: 'firstStage',
    };
  },
  created() {
    this.user_store.getAgency().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    upload(e) {
      let a = e.target.files[0];
      this.user_store.userProfile.image_path = a.name;
    },

    onSubmit() {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        firstName: this.user_store.userProfile.firstName,
        lastName: this.user_store.userProfile.lastName,
        phone: this.user_store.userProfile.phone,
        nin: this.user_store.userProfile.nin,
        agency: this.user_store.userProfile.agency_id,
        offAddress: this.user_store.userProfile.officeAddress,
        residential: this.user_store.userProfile.residentialAddress,
        dob: this.user_store.userProfile.dob,
        doe: this.user_store.userProfile.doe,
        nokName: this.user_store.userProfile.nokName,
        nokTel: this.user_store.userProfile.nokTel,
        nokRel: this.user_store.userProfile.nokRel,
        nokAddress: this.user_store.userProfile.nokAddress,
        image: this.user_store.userProfile.image_path,
      };
      console.log(payload);
      this.user_store
        .updateUserProfile(payload)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            this.user_store.setLoading(false);
            toast.success('User profile updated successful');
            this.$router.push('/');
          } else {
            this.user_store.setLoading(false);
            toast.error('Failed to update user profile');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('Failed to update user profile');
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
