<template>
  <div>
    <PageLayout />
    <div
      class="w-full bg-[#f8f9fd] h-screen overflow-x-hidden lg:mb-0 sm:mb-0 hidden lg:block sm:block"
    >
      <div class="pt-20 lg:pl-[16%]">
        <div class="px-4">
          <div class="flex gap-4">
            <button
              @click="boxType = 'inbox'"
              :class="[
                'text-sm',
                boxType === 'inbox'
                  ? 'text-[#2F4A89] font-bold'
                  : 'font-medium',
              ]"
            >
              Inbox
            </button>
            <button
              @click="boxType = 'outbox'"
              :class="[
                'text-sm',
                boxType === 'outbox'
                  ? 'text-[#2F4A89] font-bold'
                  : 'font-medium',
              ]"
            >
              Outbox
            </button>
          </div>
          <div
            v-if="boxType === 'inbox'"
            class="bg-white my-2 rounded-lg h-[85vh]"
          >
            <div v-if="user_store.inbox.length !== 0">
              <div
                v-if="contactSupport === true"
                class="flex justify-center pt-10"
              >
                <ContactSupport @NagivateInbox="contactSupport = false" />
              </div>
              <div v-else class="py-3">
                <div class="flex justify-end">
                  <button
                    class="bg-[#2F4A89] py-2 px-4 gap-2 text-sm rounded-md justify-between text-white"
                    @click="contactSupport = true"
                  >
                    Start a conversation
                  </button>
                </div>
                <p class="text-center mt-[10rem] opacity-40 font-bold text-xl">
                  Click button to start a conversation
                </p>
              </div>
            </div>

            <div v-else class="flex">
              <div class="w-[300px] h-[85vh] border-r">
                <div class="py-4">
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'profile' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'profile'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'password' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'password'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'support' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'support'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Main -->
              <div class="w-full">
                <div
                  class="flex justify-end py-2 px-2 border-b border-[#E1E1E1]"
                >
                  <button
                    class="flex items-center gap-1 border border-[#E1E1E1] p-2 rounded-lg hover:bg-blue-50"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_491_1548)">
                        <path
                          d="M9.33339 6.00083V4.94083C9.33339 4.34749 10.0534 4.04749 10.4734 4.46749L13.5334 7.52749C13.7934 7.78749 13.7934 8.20749 13.5334 8.46749L10.4734 11.5275C10.0534 11.9475 9.33339 11.6542 9.33339 11.0608V9.93416C6.00006 9.93416 3.66673 11.0008 2.00006 13.3342C2.66673 10.0008 4.66673 6.66749 9.33339 6.00083Z"
                          fill="#2F4A89"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_491_1548">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(-1 0 0 1 16 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span class="text-xs">Reply</span>
                  </button>
                </div>
                <div class="">
                  <div v-if="isActive === 'profile'">
                    <MessageDetails />
                  </div>
                  <div v-if="isActive === 'password'">
                    <MessageDetails />
                  </div>
                  <div v-if="isActive === 'support'">
                    <MessageDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="boxType === 'outbox'">
            <div
              v-if="user_store.outbox.length == 0"
              class="flex items-center justify-center py-20"
            >
              <p>No outbox yet</p>
            </div>
            <div v-else class="bg-white my-2 rounded-lg flex">
              <div class="w-[300px] h-[85vh] border-r">
                <div class="py-4">
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'profile' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'profile'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'password' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'password'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="[
                      'py-4 px-2 cursor-pointer',
                      isActive === 'support' ? 'bg-[#F4F7FF] font-medium' : '',
                    ]"
                    @click="isActive = 'support'"
                  >
                    <div class="flex gap-2 justify-between">
                      <input
                        type="radio"
                        id="huey"
                        name="drone"
                        value="huey"
                        unchecked
                      />
                      <div>
                        <p class="text-xs text-[#222222] font-semibold">
                          Admin
                        </p>
                        <p class="text-xs font-light">
                          Hello Chike, your request has been gr,..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Main -->
              <div class="w-full">
                <div
                  class="flex justify-end py-2 px-2 border-b border-[#E1E1E1]"
                >
                  <button
                    class="flex items-center gap-1 border border-[#E1E1E1] p-2 rounded-lg hover:bg-blue-50"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_491_1548)">
                        <path
                          d="M9.33339 6.00083V4.94083C9.33339 4.34749 10.0534 4.04749 10.4734 4.46749L13.5334 7.52749C13.7934 7.78749 13.7934 8.20749 13.5334 8.46749L10.4734 11.5275C10.0534 11.9475 9.33339 11.6542 9.33339 11.0608V9.93416C6.00006 9.93416 3.66673 11.0008 2.00006 13.3342C2.66673 10.0008 4.66673 6.66749 9.33339 6.00083Z"
                          fill="#2F4A89"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_491_1548">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="matrix(-1 0 0 1 16 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span class="text-xs">Reply</span>
                  </button>
                </div>
                <div class="">
                  <div v-if="isActive === 'profile'">
                    <MessageDetails />
                  </div>
                  <div v-if="isActive === 'password'">
                    <MessageDetails />
                  </div>
                  <div v-if="isActive === 'support'">
                    <MessageDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="bg-[#f8f9fd] block lg:hidden sm:hidden w-full h-screen">
      <div class="pt-20 px-4">
        <div class="flex gap-4">
          <button
            @click="boxType = 'inbox'"
            :class="[
              'text-sm',
              boxType === 'inbox' ? 'text-[#2F4A89] font-bold' : 'font-medium',
            ]"
          >
            Inbox
          </button>
          <button
            @click="boxType = 'outbox'"
            :class="[
              'text-sm',
              boxType === 'outbox' ? 'text-[#2F4A89] font-bold' : 'font-medium',
            ]"
          >
            Outbox
          </button>
        </div>

        <div class="py-4">
          <div
            :class="[
              'py-4 px-2 cursor-pointer',
              isActive === 'profile' ? 'bg-[#F4F7FF] font-medium' : '',
            ]"
            @click="isActive = 'profile'"
          >
            <div class="flex gap-4">
              <input
                type="radio"
                id="huey"
                name="drone"
                value="huey"
                unchecked
              />
              <div class="w-full">
                <div class="flex justify-between items-center">
                  <p class="text-xs text-[#222222] font-semibold">Admin</p>
                  <p class="text-xs text-[#222222] font-normal">12:00PM</p>
                </div>
                <p class="text-xs font-light">
                  Hello Chike, your request has been gr,..
                </p>
              </div>
            </div>
          </div>
          <div
            :class="[
              'py-4 px-2 cursor-pointer',
              isActive === 'password' ? 'bg-[#F4F7FF] font-medium' : '',
            ]"
            @click="isActive = 'password'"
          >
            <div class="flex gap-4">
              <input
                type="radio"
                id="huey"
                name="drone"
                value="huey"
                unchecked
              />
              <div class="w-full">
                <div class="flex justify-between items-center">
                  <p class="text-xs text-[#222222] font-semibold">Admin</p>
                  <p class="text-xs text-[#222222] font-normal">12:00PM</p>
                </div>
                <p class="text-xs font-light">
                  Hello Chike, your request has been gr,..
                </p>
              </div>
            </div>
          </div>
          <div
            :class="[
              'py-4 px-2 cursor-pointer',
              isActive === 'support' ? 'bg-[#F4F7FF] font-medium' : '',
            ]"
            @click="isActive = 'support'"
          >
            <div class="flex gap-4">
              <input
                type="radio"
                id="huey"
                name="drone"
                value="huey"
                unchecked
              />
              <div class="w-full">
                <div class="flex justify-between items-center">
                  <p class="text-xs text-[#222222] font-semibold">Admin</p>
                  <p class="text-xs text-[#222222] font-normal">12:00PM</p>
                </div>
                <p class="text-xs font-light">
                  Hello Chike, your request has been gr,..
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import ContactSupport from '@/components/ContactSupport.vue';
import MessageDetails from '@/components/MessageDetails.vue';
import { useUserStore } from '@/stores/user';

export default {
  name: 'MessagesPage',
  components: {
    PageLayout,
    ContactSupport,
    MessageDetails,
  },
  setup() {
    const user_store = useUserStore();
    return { user_store };
  },
  data() {
    return {
      boxType: 'inbox',
      isActive: 'profile',
      contactSupport: false,
    };
  },
  created() {
    this.user_store.getInbox().catch((error) => {
      console.log(error);
    });
    this.user_store.getOutbox().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    goBack() {
      this.isActive = 'profile';
    },
    logoutUser() {
      this.user_store.logout();
      this.$router.push('/login');
    },
  },
};
</script>
<style lang=""></style>
