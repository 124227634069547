<template>
  <div
    class="lg:w-[495px] sm:w-[495px] w-[360px] lg:p-5 sm:p-5 rounded-[10px] lg:border sm:border border-[#F5F5F5]"
  >
    <div>
      <div class="block lg:hidden sm:hidden">
        <div class="flex items-center">
          <svg
            @click="$emit('back')"
            class="cursor-pointer"
            width="18"
            height="18"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9173 6.50016H1.58398M1.58398 6.50016L6.25065 11.1668M1.58398 6.50016L6.25065 1.8335"
              stroke="#334DAA"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="text-[#222222] text-[16px] font-bold py-4 px-2">
            Change Password
          </p>
        </div>
      </div>
      <Form 
        @submit="onSubmit"
        :validation-schema="schema"
        v-slot="{ errors }"
        class="pt-3">
        <div class="flex flex-col w-full pb-4">
          <label class="text-[#222222] text-[13px] font-medium pb-1"
            >Current password</label
          >
          <Field
            class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
            :class="{ 'bg-red-100': errors.oldPassword }"
            type="password"
            name="oldPassword"
            placeholder="Enter your current password"
          />
          <span class="text-red-500 text-xs">{{ errors.oldPassword }}</span>
        </div>
        <div class="flex flex-col w-full pb-4">
          <label class="text-[#222222] text-[13px] font-medium pb-1"
            >New password</label
          >
          <Field
            class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
            :class="{ 'bg-red-100': errors.newPassword }"
            type="password"
            name="newPassword"
            placeholder="Enter your new password"
          />
          <span class="text-red-500 text-xs">{{ errors.newPassword }}</span>
        </div>
        <div class="flex flex-col w-full pb-4">
          <label class="text-[#222222] text-[13px] font-medium pb-1"
            >Confirm new password</label
          >
          <Field
            class="border border-[#F5F5F5] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
            :class="{ 'bg-red-100': errors.newPassword }"
            type="password"
            name="confirmPassword"
            placeholder="Re enter new password"
          />
          <span class="text-red-500 text-xs">{{ errors.confirmPassword }}</span>
        </div>
        <div class="pt-4 pb-2">
          <button
            type="submit"
            class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
          >
            <span>Save changes</span>
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';
export default {
  name: 'ChangePassword',
  components: {
    Field,
    Form,
  },
  setup() {
    const schema = Yup.object().shape({
      oldPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      newPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('New Password is required'),
      confirmPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('New Password must be confirmed'),
    });
    const user_store = useUserStore();
    return { user_store, schema };
  },
  methods: {
    onSubmit(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        currentPassword: values.oldPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
      };
      console.log(payload);
      this.user_store
        .changeUserPassword(payload)
        .then((res) => {
          if (res.status === true) {
            this.user_store.setLoading(false);
            toast.success('User password changed successfully');
            this.$router.push('/');
          } else {
            this.user_store.setLoading(false);
            toast.error(res.data.response.data.message);
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('Unable to change password');
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
