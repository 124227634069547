<template>
  <div class="hidden lg:block sm:block">
    <div
      class="background-image-container flex justify-center items-center overflow-hidden"
    >
      <div
        class="bg-white w-[703px] h-[429px] p-2 rounded-2xl flex gap-2 relative"
      >
        <div
          class="bg-[#2F4A89] w-[267px] flex flex-col justify-between rounded-xl text-white p-6"
        >
          <div class="font-semibold text-xs tracking-[0.15em]">
            {{ user_store?.companyProfile?.name }}
          </div>
          <div class="">
            <img :src="user_store?.companyProfile?.image_path" alt="logo" />
          </div>
          <div class="">
            <p class="font-semibold text-xl tracking-wide">
              Welcome to {{ user_store?.companyProfile?.name }}
            </p>
            <p class="pt-3 text-sm font-[400px] leading-relaxed">
              {{ user_store?.companyProfile?.slogan }}
            </p>
          </div>
        </div>

        <div class="pl-8 pr-6 py-6 w-[412px]">
          <div class="pb-6">
            <p class="text-[#222222] font-bold text-2xl pb-1 tracking-wide">
              Member log in
            </p>
            <p class="text-[#888888] text-sm font-normal">
              Enter your details to log in to your account
            </p>
          </div>
          <!-- Form -->
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="flex flex-col w-full pb-5">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Email address</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.email }"
                type="email"
                name="email"
                placeholder="Enter email address"
              />
              <span class="text-red-500 text-xs">{{ errors.email }}</span>
            </div>
            <div class="flex flex-col w-full pb-5">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Password</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.password }"
                type="password"
                name="password"
                placeholder="Enter your password"
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-12 mt-9 cursor-pointer"
              >
                <g clip-path="url(#clip0_8_217)">
                  <path
                    d="M7.99996 3.16667C4.66663 3.16667 1.81996 5.24 0.666626 8.16667C1.81996 11.0933 4.66663 13.1667 7.99996 13.1667C11.3333 13.1667 14.18 11.0933 15.3333 8.16667C14.18 5.24 11.3333 3.16667 7.99996 3.16667ZM7.99996 11.5C6.15996 11.5 4.66663 10.0067 4.66663 8.16667C4.66663 6.32667 6.15996 4.83333 7.99996 4.83333C9.83996 4.83333 11.3333 6.32667 11.3333 8.16667C11.3333 10.0067 9.83996 11.5 7.99996 11.5ZM7.99996 6.16667C6.89329 6.16667 5.99996 7.06 5.99996 8.16667C5.99996 9.27333 6.89329 10.1667 7.99996 10.1667C9.10663 10.1667 9.99996 9.27333 9.99996 8.16667C9.99996 7.06 9.10663 6.16667 7.99996 6.16667Z"
                    fill="#D7D7D7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8_217">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span class="text-red-500 text-xs">{{ errors.password }}</span>
            </div>
            <div class="flex justify-between items-start">
              <div class="text-white pb-6">
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[40px] w-[176px]"
              >
                <span v-if="user_store.loadingStatus">
                  Loading...
                </span>
                <span v-else>Log in</span>
              </button>
            </div>
            <button @click="$router.push('/reset-password')" class="text-red-500 text-xs font-normal">Forgot Password?</button>
            </div>
            <div class="text-[#2F4A89] text-xs font-normal">
              Don't have an account?
              <span @click="$router.push('/register')" class="cursor-pointer"
                >Register</span
              >
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile Responsive -->
  <div class="block lg:hidden sm:hidden">
    <div class="bg-[#2F4A89] w-full h-screen overflow-hidden relative">
      <div class="p-4">
        <div class="w-[137px] pt-[10%]">
          <img :src="user_store?.companyProfile?.image_path" alt="logo" />
        </div>
        <div class="text-white pt-[30%]">
          <p class="font-semibold text-lg tracking-wide">
            Welcome to {{ user_store?.companyProfile?.name }}
          </p>
          <p class="pt-3 text-sm font-[400px] leading-relaxed">
            {{ user_store?.companyProfile?.slogan }}
          </p>
        </div>
      </div>
      <div class="absolute bottom-0 w-full">
        <div
          class="bg-[#FFFFFF] mt-[20%] rounded-t-xl py-[10%] px-4 w-full bottom-0"
        >
          <div class="pb-6">
            <p class="text-[#222222] font-bold text-2xl pb-1 tracking-wide">
              Member log in
            </p>
            <p class="text-[#888888] text-sm font-normal">
              Enter your details to log in to your account
            </p>
          </div>
          <!-- Form -->
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="flex flex-col w-full pb-6">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Email address</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.email }"
                type="email"
                name="email"
                placeholder="Enter email address"
              />
              <span class="text-red-500 text-xs">{{ errors.email }}</span>
            </div>
            <div class="flex flex-col w-full pb-6">
              <label class="text-[#222222] text-xs font-medium pb-1"
                >Password</label
              >
              <Field
                class="border border-[#F5F5F5] rounded-lg px-[10px] py-4 text-sm focus:border-0 focus:ring-[#2f4a89c6]"
                :class="{ 'bg-red-100': errors.password }"
                type="password"
                name="password"
                placeholder="Enter your password"
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-12 mt-9 cursor-pointer"
              >
                <g clip-path="url(#clip0_8_217)">
                  <path
                    d="M7.99996 3.16667C4.66663 3.16667 1.81996 5.24 0.666626 8.16667C1.81996 11.0933 4.66663 13.1667 7.99996 13.1667C11.3333 13.1667 14.18 11.0933 15.3333 8.16667C14.18 5.24 11.3333 3.16667 7.99996 3.16667ZM7.99996 11.5C6.15996 11.5 4.66663 10.0067 4.66663 8.16667C4.66663 6.32667 6.15996 4.83333 7.99996 4.83333C9.83996 4.83333 11.3333 6.32667 11.3333 8.16667C11.3333 10.0067 9.83996 11.5 7.99996 11.5ZM7.99996 6.16667C6.89329 6.16667 5.99996 7.06 5.99996 8.16667C5.99996 9.27333 6.89329 10.1667 7.99996 10.1667C9.10663 10.1667 9.99996 9.27333 9.99996 8.16667C9.99996 7.06 9.10663 6.16667 7.99996 6.16667Z"
                    fill="#D7D7D7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8_217">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span class="text-red-500 text-xs">{{ errors.password }}</span>
            </div>
            <div class="text-white pb-2">
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[45px] w-full"
              >
                <span v-if="user_store.loadingStatus">Loading...</span>
                <span v-else>Log in</span>
              </button>
            </div>
            <button @click="$router.push('/reset-password')" class="text-red-500 text-xs font-normal pb-4 text-end w-full">Forgot Password?</button>

            <div class="text-[#2F4A89] text-xs font-normal">
              Don't have an account?
              <span @click="$router.push('/register')" class="cursor-pointer"
                >Register</span
              >
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';

export default {
  name: 'LoginPage',
  components: {
    Field,
    Form,
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    });
    const user_store = useUserStore();
    return { user_store, schema };
  },
  data() {
    return {};
  },
  created() {
    this.user_store.companyDetails().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    onSubmit(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        email: values.email,
        password: values.password,
      };
      console.log(payload);
      this.user_store
        .loginUser(payload)
        .then((res) => {
          console.log(res.data.token);
          if (res.data.token) {
            this.user_store.setLoading(false);
            toast.success('User login successful');
            this.$router.push('/');
          } else {
            this.user_store.setLoading(false);
            toast.error('unable to login');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('unable to login');
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.background-image-container {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/img/bg.png');
  background-size: cover;
  background-position: center;
  background-color: #111111;
}
</style>
