import { defineStore } from 'pinia';
import guarantorService from '@/helper/services/api';

export const useGuarantorStore = defineStore('guarantorStore', {
  state: () => ({
    pendingGuarantors: null,
    incomingGuarantors: [],
    outgoingGuarantors: [],
    dashboardIncomingGuarantors: [],
    loading: false,
  }),
  getters: {
    loadingStatus: (state) => state.loading,
  },
  actions: {
    setLoading(status) {
      this.loading = status;
    },
    removeGuarantor(id) {
      console.log('testing!!!', this.pendingGuarantors.loan_guarantor, id);
      const guarantors = {
        ...this.pendingGuarantors,
        loan_guarantor: this.pendingGuarantors.loan_guarantor.filter(
          (n) => +n.id !== +id
        ),
      };
      this.pendingGuarantors = guarantors;
    },
    async addGuarantors(payload) {
      try {
        const res = await guarantorService.addGuarantors(payload);
        console.log('guarantor testing', res);
        return { data: res, status: true };
      } catch (error) {
        console.log(error);
        return { data: error, status: false };
      }
    },
    async fetchGuarantor() {
      try {
        const { data } = await guarantorService.fetchGuarantor();
        this.pendingGuarantors = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchIncomingGuarantor() {
      try {
        this.loading = true;
        const { data } = await guarantorService.fetchIncomingGuarantor();
        this.incomingGuarantors = data;
        this.loading = false;
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchOutingGuarantor() {
      try {
        this.loading = true;
        const { data } = await guarantorService.fetchOutgoingGuarantor();
        this.outgoingGuarantors = data;
        this.loading = false;
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchDashboardIncomingGuarantor() {
      try {
        const { data } =
          await guarantorService.fetchDashboardIncomingGuarantor();
        this.dashboardIncomingGuarantors = data;
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async acceptGuarantorRequest(id) {
      try {
        const res = await guarantorService.acceptRequest(id);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: error, status: false };
      }
    },
    async declineGuarantorRequest(id) {
      try {
        const res = await guarantorService.declineRequest(id);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: error, status: false };
      }
    },
    async negotiatewithGuarantor(id, payload) {
      try {
        const res = await guarantorService.negotiatewithGuarantor(id, payload);
        console.log('negogiate with guarantor', res.data);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
  },
});
