<template>
  <div
    class="w-[16%] top-[58px] border-r border-[#F5F5F5] left-0 z-30 fixed bg-[#FFFFFF] hidden lg:block"
  >
    <div class="h-screen p-6 text-black">
      <div>
        <router-link
          v-for="(route, i) in routeList"
          :key="i"
          :to="route.path"
          activeclassname="active"
        >
          <div
            :class="[
              'flex items-center my-4 py-3 px-4',
              isActive(route.path) ? 'bg-[#2F4A89] rounded-lg text-white' : '',
            ]"
          >
            <LogoComponent
              :type="route.name"
              alt="logo"
              :isActive="isActive(route.path)"
            />
            <span class="pl-3 font-normal text-md">{{ route.name }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <!-- Mobile responsive -->
  <div
    class="block lg:hidden bg-[#FFFFFF] border-t border-[#F5F5F5] bottom-0 fixed z-50 py-4 px-2 rounded-t-xl w-full"
  >
    <div class="text-black sm:px-10">
      <div class="flex justify-between w-full">
        <router-link
          v-for="(route, i) in mobileRouteList"
          :key="i"
          :to="route.path"
          activeclassname="active"
        >
          <div
            :class="[
              'flex flex-col items-center justify-center',
              isActive(route.path) ? 'text-[#2F4A89] font-semibold' : '',
            ]"
          >
            <LogoComponent
              :type="route.name"
              alt="logo"
              :isActive="isActive(route.path)"
            />
            <span class="pt-2 font-normal text-xs">{{ route.name }}</span>
          </div>
        </router-link>
        <div>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_738_402)">
              <path
                d="M5.29948 8.33301C4.38281 8.33301 3.63281 9.08301 3.63281 9.99967C3.63281 10.9163 4.38281 11.6663 5.29948 11.6663C6.21615 11.6663 6.96615 10.9163 6.96615 9.99967C6.96615 9.08301 6.21615 8.33301 5.29948 8.33301ZM15.2995 8.33301C14.3828 8.33301 13.6328 9.08301 13.6328 9.99967C13.6328 10.9163 14.3828 11.6663 15.2995 11.6663C16.2161 11.6663 16.9661 10.9163 16.9661 9.99967C16.9661 9.08301 16.2161 8.33301 15.2995 8.33301ZM10.2995 8.33301C9.38281 8.33301 8.63281 9.08301 8.63281 9.99967C8.63281 10.9163 9.38281 11.6663 10.2995 11.6663C11.2161 11.6663 11.9661 10.9163 11.9661 9.99967C11.9661 9.08301 11.2161 8.33301 10.2995 8.33301Z"
                fill="#ADB7CC"
              />
            </g>
            <defs>
              <clipPath id="clip0_738_402">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.298828)"
                />
              </clipPath>
            </defs>
          </svg>
          <p class="pt-3 font-normal text-xs text-center">More</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { routeList, mobileRouteList } from '@/helper/util.js';
import LogoComponent from '@/components/global/LogoComponent.vue';
export default {
  name: 'PageSidebar',
  components: { LogoComponent },
  data() {
    return {
      routeList: routeList,
      mobileRouteList: mobileRouteList,
    };
  },
  mounted() {
  },
  methods: {
    isActive(pathname) {
      return pathname ? this.$route.path === pathname : null;
    },
  },
};
</script>
<style lang="scss">
.active svg {
  fill: #fff;
}
</style>
