<template>
  <div class="hidden lg:block">
    <svg v-if="type==='Savings'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0895 5.40005L11.614 3.92455C11.6595 3.65155 11.731 3.39805 11.822 3.17705C11.8935 3.00805 11.9195 2.81305 11.8805 2.61155C11.796 2.14355 11.341 1.81855 10.86 1.82505C9.8265 1.84455 8.91 2.35155 8.325 3.12505H5.075C3.099 3.12505 1.5 4.72405 1.5 6.70005C1.5 8.16255 2.3905 11.562 2.852 13.226C3.008 13.785 3.5215 14.175 4.1065 14.175H5.4C6.115 14.175 6.7 13.59 6.7 12.875H8C8 13.59 8.585 14.175 9.3 14.175H10.6065C11.1785 14.175 11.6855 13.798 11.8545 13.2455L12.667 10.5415L14.058 10.0735C14.3245 9.98255 14.5 9.73555 14.5 9.45605V6.05005C14.5 5.69255 14.2075 5.40005 13.85 5.40005H13.0895ZM8 6.37505H6.05C5.6925 6.37505 5.4 6.08255 5.4 5.72505C5.4 5.36755 5.6925 5.07505 6.05 5.07505H8C8.3575 5.07505 8.65 5.36755 8.65 5.72505C8.65 6.08255 8.3575 6.37505 8 6.37505ZM10.6 7.67505C10.2425 7.67505 9.95 7.38255 9.95 7.02505C9.95 6.66755 10.2425 6.37505 10.6 6.37505C10.9575 6.37505 11.25 6.66755 11.25 7.02505C11.25 7.38255 10.9575 7.67505 10.6 7.67505Z"
          :fill="isActive ? '#ffffff' : '#506CC0'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Marketplace'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4343 5.32834L13.7517 3.13771C13.6087 2.55268 13.1017 2.14966 12.5102 2.14966H3.48115C2.89612 2.14966 2.38259 2.55918 2.24608 3.13771L1.56354 5.32834C1.26453 6.60891 2.11608 7.34995 2.14858 7.38896V12.5502C2.14858 13.2653 2.73361 13.8503 3.44865 13.8503H12.5492C13.2642 13.8503 13.8492 13.2653 13.8492 12.5502V7.38896C14.5773 6.66091 14.5578 5.82237 14.4343 5.32834ZM8.64895 3.44973H9.92302L10.274 5.73786C10.3325 6.19939 10.0205 6.69992 9.44199 6.69992C9.00647 6.69992 8.64895 6.31639 8.64895 5.84837V3.44973ZM4.38471 5.95887C4.3327 6.3814 3.99468 6.69992 3.59816 6.69992C2.99363 6.69992 2.72061 6.06938 2.82462 5.63386L3.48115 3.44973H4.76173L4.38471 5.95887ZM7.34887 5.84837C7.34887 6.31639 6.99135 6.69992 6.51033 6.69992C6.0228 6.69992 5.66528 6.24489 5.71728 5.73786L6.0748 3.44973H7.34887V5.84837ZM12.3997 6.69992C12.0031 6.69992 11.6586 6.3814 11.6131 5.95887L11.2361 3.44973L12.4907 3.44323L13.1732 5.63386C13.2772 6.06938 13.0107 6.69992 12.3997 6.69992Z"
          :fill="isActive ? '#ffffff' : '#506CC0'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Dashboard'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_15_943)">
          <path
            d="M2.66667 8.66667H6.66667C7.03333 8.66667 7.33333 8.36667 7.33333 8V2.66667C7.33333 2.3 7.03333 2 6.66667 2H2.66667C2.3 2 2 2.3 2 2.66667V8C2 8.36667 2.3 8.66667 2.66667 8.66667ZM2.66667 14H6.66667C7.03333 14 7.33333 13.7 7.33333 13.3333V10.6667C7.33333 10.3 7.03333 10 6.66667 10H2.66667C2.3 10 2 10.3 2 10.6667V13.3333C2 13.7 2.3 14 2.66667 14ZM9.33333 14H13.3333C13.7 14 14 13.7 14 13.3333V8C14 7.63333 13.7 7.33333 13.3333 7.33333H9.33333C8.96667 7.33333 8.66667 7.63333 8.66667 8V13.3333C8.66667 13.7 8.96667 14 9.33333 14ZM8.66667 2.66667V5.33333C8.66667 5.7 8.96667 6 9.33333 6H13.3333C13.7 6 14 5.7 14 5.33333V2.66667C14 2.3 13.7 2 13.3333 2H9.33333C8.96667 2 8.66667 2.3 8.66667 2.66667Z"
            :fill="isActive ? '#ffffff' : '#2F4A89'"
          />
        </g>
        <defs>
          <clipPath id="clip0_15_943">
            <rect width="16" height="16" :fill="isActive ? '#ffffff' : '#2F4A89'" />
          </clipPath>
        </defs>
      </svg>
      <svg v-if="type === 'Loans'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5.45463V11.8183C15 12.5183 14.4273 13.091 13.7273 13.091H3.54545C3.19545 13.091 2.90909 12.8046 2.90909 12.4546C2.90909 12.1046 3.19545 11.8183 3.54545 11.8183H13.7273V5.45463C13.7273 5.10463 14.0136 4.81827 14.3636 4.81827C14.7136 4.81827 15 5.10463 15 5.45463ZM2.90909 10.5455C1.85273 10.5455 1 9.69282 1 8.63645V4.81827C1 3.76191 1.85273 2.90918 2.90909 2.90918H10.5455C11.6018 2.90918 12.4545 3.76191 12.4545 4.81827V9.27282C12.4545 9.97282 11.8818 10.5455 11.1818 10.5455H2.90909ZM4.81818 6.72736C4.81818 7.78373 5.67091 8.63645 6.72727 8.63645C7.78364 8.63645 8.63636 7.78373 8.63636 6.72736C8.63636 5.671 7.78364 4.81827 6.72727 4.81827C5.67091 4.81827 4.81818 5.671 4.81818 6.72736Z"
          :fill="isActive ? '#ffffff' : '#506CC0'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Guarantors'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5.45463V11.8183C15 12.5183 14.4273 13.091 13.7273 13.091H3.54545C3.19545 13.091 2.90909 12.8046 2.90909 12.4546C2.90909 12.1046 3.19545 11.8183 3.54545 11.8183H13.7273V5.45463C13.7273 5.10463 14.0136 4.81827 14.3636 4.81827C14.7136 4.81827 15 5.10463 15 5.45463ZM2.90909 10.5455C1.85273 10.5455 1 9.69282 1 8.63645V4.81827C1 3.76191 1.85273 2.90918 2.90909 2.90918H10.5455C11.6018 2.90918 12.4545 3.76191 12.4545 4.81827V9.27282C12.4545 9.97282 11.8818 10.5455 11.1818 10.5455H2.90909ZM4.81818 6.72736C4.81818 7.78373 5.67091 8.63645 6.72727 8.63645C7.78364 8.63645 8.63636 7.78373 8.63636 6.72736C8.63636 5.671 7.78364 4.81827 6.72727 4.81827C5.67091 4.81827 4.81818 5.671 4.81818 6.72736Z"
          :fill="isActive ? '#ffffff' : '#506CC0'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Voting'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM5.33333 11.3333C4.96667 11.3333 4.66667 11.0333 4.66667 10.6667V7.33333C4.66667 6.96667 4.96667 6.66667 5.33333 6.66667C5.7 6.66667 6 6.96667 6 7.33333V10.6667C6 11.0333 5.7 11.3333 5.33333 11.3333ZM8 11.3333C7.63333 11.3333 7.33333 11.0333 7.33333 10.6667V5.33333C7.33333 4.96667 7.63333 4.66667 8 4.66667C8.36667 4.66667 8.66667 4.96667 8.66667 5.33333V10.6667C8.66667 11.0333 8.36667 11.3333 8 11.3333ZM10.6667 11.3333C10.3 11.3333 10 11.0333 10 10.6667V9.33333C10 8.96667 10.3 8.66667 10.6667 8.66667C11.0333 8.66667 11.3333 8.96667 11.3333 9.33333V10.6667C11.3333 11.0333 11.0333 11.3333 10.6667 11.3333Z"
          :fill="isActive ? '#ffffff' : '#506CC0'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
  </div>

  <!-- Mobile -->
  <div class="lg:hidden block">
    <svg v-if="type==='Savings'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0895 5.40005L11.614 3.92455C11.6595 3.65155 11.731 3.39805 11.822 3.17705C11.8935 3.00805 11.9195 2.81305 11.8805 2.61155C11.796 2.14355 11.341 1.81855 10.86 1.82505C9.8265 1.84455 8.91 2.35155 8.325 3.12505H5.075C3.099 3.12505 1.5 4.72405 1.5 6.70005C1.5 8.16255 2.3905 11.562 2.852 13.226C3.008 13.785 3.5215 14.175 4.1065 14.175H5.4C6.115 14.175 6.7 13.59 6.7 12.875H8C8 13.59 8.585 14.175 9.3 14.175H10.6065C11.1785 14.175 11.6855 13.798 11.8545 13.2455L12.667 10.5415L14.058 10.0735C14.3245 9.98255 14.5 9.73555 14.5 9.45605V6.05005C14.5 5.69255 14.2075 5.40005 13.85 5.40005H13.0895ZM8 6.37505H6.05C5.6925 6.37505 5.4 6.08255 5.4 5.72505C5.4 5.36755 5.6925 5.07505 6.05 5.07505H8C8.3575 5.07505 8.65 5.36755 8.65 5.72505C8.65 6.08255 8.3575 6.37505 8 6.37505ZM10.6 7.67505C10.2425 7.67505 9.95 7.38255 9.95 7.02505C9.95 6.66755 10.2425 6.37505 10.6 6.37505C10.9575 6.37505 11.25 6.66755 11.25 7.02505C11.25 7.38255 10.9575 7.67505 10.6 7.67505Z"
          :fill="isActive ? '#334DAA' : '#ADB7CC'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Marketplace'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4343 5.32834L13.7517 3.13771C13.6087 2.55268 13.1017 2.14966 12.5102 2.14966H3.48115C2.89612 2.14966 2.38259 2.55918 2.24608 3.13771L1.56354 5.32834C1.26453 6.60891 2.11608 7.34995 2.14858 7.38896V12.5502C2.14858 13.2653 2.73361 13.8503 3.44865 13.8503H12.5492C13.2642 13.8503 13.8492 13.2653 13.8492 12.5502V7.38896C14.5773 6.66091 14.5578 5.82237 14.4343 5.32834ZM8.64895 3.44973H9.92302L10.274 5.73786C10.3325 6.19939 10.0205 6.69992 9.44199 6.69992C9.00647 6.69992 8.64895 6.31639 8.64895 5.84837V3.44973ZM4.38471 5.95887C4.3327 6.3814 3.99468 6.69992 3.59816 6.69992C2.99363 6.69992 2.72061 6.06938 2.82462 5.63386L3.48115 3.44973H4.76173L4.38471 5.95887ZM7.34887 5.84837C7.34887 6.31639 6.99135 6.69992 6.51033 6.69992C6.0228 6.69992 5.66528 6.24489 5.71728 5.73786L6.0748 3.44973H7.34887V5.84837ZM12.3997 6.69992C12.0031 6.69992 11.6586 6.3814 11.6131 5.95887L11.2361 3.44973L12.4907 3.44323L13.1732 5.63386C13.2772 6.06938 13.0107 6.69992 12.3997 6.69992Z"
          :fill="isActive ? '#334DAA' : '#ADB7CC'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Dashboard'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_15_943)">
          <path
            d="M2.66667 8.66667H6.66667C7.03333 8.66667 7.33333 8.36667 7.33333 8V2.66667C7.33333 2.3 7.03333 2 6.66667 2H2.66667C2.3 2 2 2.3 2 2.66667V8C2 8.36667 2.3 8.66667 2.66667 8.66667ZM2.66667 14H6.66667C7.03333 14 7.33333 13.7 7.33333 13.3333V10.6667C7.33333 10.3 7.03333 10 6.66667 10H2.66667C2.3 10 2 10.3 2 10.6667V13.3333C2 13.7 2.3 14 2.66667 14ZM9.33333 14H13.3333C13.7 14 14 13.7 14 13.3333V8C14 7.63333 13.7 7.33333 13.3333 7.33333H9.33333C8.96667 7.33333 8.66667 7.63333 8.66667 8V13.3333C8.66667 13.7 8.96667 14 9.33333 14ZM8.66667 2.66667V5.33333C8.66667 5.7 8.96667 6 9.33333 6H13.3333C13.7 6 14 5.7 14 5.33333V2.66667C14 2.3 13.7 2 13.3333 2H9.33333C8.96667 2 8.66667 2.3 8.66667 2.66667Z"
            :fill="isActive ? '#334DAA' : '#ADB7CC'"
          />
        </g>
        <defs>
          <clipPath id="clip0_15_943">
            <rect width="16" height="16" :fill="isActive ? '#334DAA' : '#ADB7CC'" />
          </clipPath>
        </defs>
      </svg>
      <svg v-if="type === 'Loans'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5.45463V11.8183C15 12.5183 14.4273 13.091 13.7273 13.091H3.54545C3.19545 13.091 2.90909 12.8046 2.90909 12.4546C2.90909 12.1046 3.19545 11.8183 3.54545 11.8183H13.7273V5.45463C13.7273 5.10463 14.0136 4.81827 14.3636 4.81827C14.7136 4.81827 15 5.10463 15 5.45463ZM2.90909 10.5455C1.85273 10.5455 1 9.69282 1 8.63645V4.81827C1 3.76191 1.85273 2.90918 2.90909 2.90918H10.5455C11.6018 2.90918 12.4545 3.76191 12.4545 4.81827V9.27282C12.4545 9.97282 11.8818 10.5455 11.1818 10.5455H2.90909ZM4.81818 6.72736C4.81818 7.78373 5.67091 8.63645 6.72727 8.63645C7.78364 8.63645 8.63636 7.78373 8.63636 6.72736C8.63636 5.671 7.78364 4.81827 6.72727 4.81827C5.67091 4.81827 4.81818 5.671 4.81818 6.72736Z"
          :fill="isActive ? '#334DAA' : '#ADB7CC'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Guarantors'"
      width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5.45463V11.8183C15 12.5183 14.4273 13.091 13.7273 13.091H3.54545C3.19545 13.091 2.90909 12.8046 2.90909 12.4546C2.90909 12.1046 3.19545 11.8183 3.54545 11.8183H13.7273V5.45463C13.7273 5.10463 14.0136 4.81827 14.3636 4.81827C14.7136 4.81827 15 5.10463 15 5.45463ZM2.90909 10.5455C1.85273 10.5455 1 9.69282 1 8.63645V4.81827C1 3.76191 1.85273 2.90918 2.90909 2.90918H10.5455C11.6018 2.90918 12.4545 3.76191 12.4545 4.81827V9.27282C12.4545 9.97282 11.8818 10.5455 11.1818 10.5455H2.90909ZM4.81818 6.72736C4.81818 7.78373 5.67091 8.63645 6.72727 8.63645C7.78364 8.63645 8.63636 7.78373 8.63636 6.72736C8.63636 5.671 7.78364 4.81827 6.72727 4.81827C5.67091 4.81827 4.81818 5.671 4.81818 6.72736Z"
          :fill="isActive ? '#334DAA' : '#ADB7CC'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
      <svg v-if="type === 'Voting'"
        width="25"
        height="25"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM5.33333 11.3333C4.96667 11.3333 4.66667 11.0333 4.66667 10.6667V7.33333C4.66667 6.96667 4.96667 6.66667 5.33333 6.66667C5.7 6.66667 6 6.96667 6 7.33333V10.6667C6 11.0333 5.7 11.3333 5.33333 11.3333ZM8 11.3333C7.63333 11.3333 7.33333 11.0333 7.33333 10.6667V5.33333C7.33333 4.96667 7.63333 4.66667 8 4.66667C8.36667 4.66667 8.66667 4.96667 8.66667 5.33333V10.6667C8.66667 11.0333 8.36667 11.3333 8 11.3333ZM10.6667 11.3333C10.3 11.3333 10 11.0333 10 10.6667V9.33333C10 8.96667 10.3 8.66667 10.6667 8.66667C11.0333 8.66667 11.3333 8.96667 11.3333 9.33333V10.6667C11.3333 11.0333 11.0333 11.3333 10.6667 11.3333Z"
          :fill="isActive ? '#334DAA' : '#ADB7CC'"
          :fill-opacity="!isActive && 0.75"
        />
      </svg>
  </div>
</template>

<script>
export default {
  name: "LogoComponent",
  props: {
    type: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: this.value
    }
  },
  watch: {
    value(val) {
      if (val !== this.visible) {
        this.visible = val
      }
    },
    visible() {
      this.$emit('input', this.visible);
    }
  },
}
</script>
