<template>
  <div class="bg-[#FFFFFF] rounded-lg w-full py-4">
    <div class="flex items-center">
      <div>
        <div
          v-if="cardData.loan?.member?.image_path"
          class="w-8 h-8 ml-3 cursor-pointer"
        >
          <img
            :src="cardData.loan?.member?.image_path"
            alt="logo"
            class="w-full h-full rounded-full"
          />
        </div>
        <svg
          v-else
          width="35"
          height="35"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="ml-3 cursor-pointer"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.55 13.8 7.68 13 10 13C12.32 13 14.45 13.8 16.14 15.12C14.43 17.18 12.03 18 10 18Z"
            fill="#5B6C93"
          />
        </svg>
      </div>
      <div class="pl-2">
        <p class="text-[#333333] text-xs font-semibold">
          {{
            cardData.loan?.member?.firstName
              ? cardData.loan?.member?.firstName
              : 'N/A'
          }}
          {{ cardData.loan?.member?.lastName }}
        </p>
        <p class="text-[#555555] text-[10px] font-normal">
          {{
            cardData.loan?.member?.phone ? cardData.loan?.member?.phone : 'N/A'
          }}
        </p>
      </div>
    </div>
    <div class="py-2 px-4">
      <p class="text-[#777777] text-[10px] font-normal">Liability</p>
      <p class="text-[#333333] text-[13px] font-semibold">
        N {{ formatNumber(cardData.liability) }}
      </p>
      <button
        class="bg-[#E4FCEB] w-full py-2 my-2 text-[11px] rounded-lg"
        @click="sumbitAcceptGuarantorRequest(cardData.id)"
      >
        Accept
      </button>
      <div class="flex gap-2 pt-2">
        <button
          class="bg-[#FFECEE] w-full py-2 my-1 text-[11px] text-[#222222] rounded-lg"
          @click="sumbitDeclineGuarantorRequest(cardData.id)"
        >
          Decline
        </button>
        <button
          class="bg-[#FFF9D8] w-full py-2 my-1 text-[11px] text-[#222222] rounded-lg"
        >
          Negotiate
        </button>
      </div>
    </div>
  </div>
  <!-- <MessageDialog :dialog="dialog" /> -->
</template>
<script>
import { formatNumber } from '@/helper/util';
import { useGuarantorStore } from '@/stores/guarantor';
import { useToast } from 'vue-toastification';
// import MessageDialog from './MessageDialog.vue';

export default {
  name: 'GuarantorRequestCard',
  // components: { MessageDialog },
  setup() {
    const guarantor_store = useGuarantorStore();
    return { guarantor_store };
  },
  props: {
    cardData: {
      type: null,
      required: true,
    },
  },
  data() {
    return {
      // dialog: false,
    };
  },
  mounted() {
    // console.log('dialog', this.dialog);
    // this.dialog = true;
  },
  methods: {
    formatNumber(currency) {
      return formatNumber(currency);
    },
    sumbitAcceptGuarantorRequest(id) {
      const toast = useToast();
      this.guarantor_store
        .acceptGuarantorRequest(id)
        .then((res) => {
          console.log('accept guarantor', res);
          if (res.status === true) {
            toast.success('Guarantor accepted');
          } else {
            toast.error(res.data.response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Unable to accept Guarantor');
        });
    },
    sumbitDeclineGuarantorRequest(id) {
      const toast = useToast();
      this.guarantor_store
        .declineGuarantorRequest(id)
        .then((res) => {
          console.log('decline guarantor', res);
          if (res.status === true) {
            toast.success('Guarantor declined');
          } else {
            toast.error(res.data.response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Unable to accept Guarantor');
        });
    },
  },
};
</script>
<style lang=""></style>
