<template>
  <div
    class="lg:w-[495px] sm:w-[495px] w-[360px] lg:p-5 sm:p-5 rounded-[10px] lg:border sm:border border-[#F5F5F5]"
  >
    <div>
      <div class="block lg:hidden sm:hidden">
        <div class="flex items-center">
          <svg
            @click="$emit('back')"
            class="cursor-pointer"
            width="18"
            height="18"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9173 6.50016H1.58398M1.58398 6.50016L6.25065 11.1668M1.58398 6.50016L6.25065 1.8335"
              stroke="#334DAA"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="text-[#222222] text-[16px] font-bold py-4 px-2">
            New Message
          </p>
        </div>
      </div>
      <p class="text-[#222222] font-bold text-[16px] hidden lg:block sm:block">
        New Message
      </p>
    </div>
    <!-- Form -->
    <Form
      @submit="onSubmit"
      :validation-schema="schema"
      v-slot="{ errors }"
      class="pt-4"
    >
      <div class="flex flex-col w-full pb-4">
        <label class="text-[#444444] text-[13px] font-medium pb-1"
          >Subject</label
        >
        <Field
          class="border border-[#F5F5F5] text-[#444444] rounded-md px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
          :class="{ 'bg-red-100': errors.subject }"
          type="text"
          name="subject"
          placeholder="Hello everyone"
        />
        <span class="text-red-500 text-xs">{{ errors.subject }}</span>
      </div>
      <div class="flex flex-col w-full pb-4">
        <label class="text-[#444444] text-[13px] font-medium pb-1"
          >Write your message</label
        >
        <Field
          class="border border-[#F5F5F5] text-[#444444] rounded-md px-4 py-5 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
          :class="{ 'bg-red-100': errors.message }"
          type="text"
          name="message"
          placeholder="Something just happened right now!"
        />
        <span class="text-red-500 text-xs">{{ errors.message }}</span>
      </div>
      <div class="flex flex-col w-full pb-4">
        <label class="text-[#444444] text-[13px] font-medium pb-1"
          >Attach file (Optional)</label
        >
        <div
          class="flex border px-3 py-4 bg-[#fbfcfc] border-dashed border-[#2F4A89] rounded-md"
        >
          <input
            class="absolute left-[-100%] h-[48px] w-[200%] text-xs cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0"
            type="file"
            @change="upload($event)"
          />
          <div class="flex items-center">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="4" fill="#D8E3FF" />
              <path
                d="M20.94 12.9407L17.72 9.72065C17.4733 9.47398 17.1333 9.33398 16.78 9.33398H12C11.2666 9.33398 10.6733 9.93398 10.6733 10.6673L10.6666 21.334C10.6666 22.0673 11.26 22.6673 11.9933 22.6673H20C20.7333 22.6673 21.3333 22.0673 21.3333 21.334V13.8873C21.3333 13.534 21.1933 13.194 20.94 12.9407ZM17.8666 18.0007H16.6666V20.0007C16.6666 20.3673 16.3666 20.6673 16 20.6673C15.6333 20.6673 15.3333 20.3673 15.3333 20.0007V18.0007H14.14C13.84 18.0007 13.6933 17.6407 13.9066 17.434L15.7733 15.574C15.9066 15.4473 16.1133 15.4473 16.2466 15.574L18.1066 17.434C18.3066 17.6407 18.16 18.0007 17.8666 18.0007ZM17.3333 14.0007C16.9666 14.0007 16.6666 13.7007 16.6666 13.334V10.334L20.3333 14.0007H17.3333Z"
                fill="#334DAA"
              />
            </svg>
            <div v-if="image === ''" class="text-xs leading-5 px-2">
              <p class="text-[#222222]">
                Drag files her to upload or
                <b class="text-[#2F4A89]">click to browse</b>
              </p>
            </div>
            <div v-else class="text-xs leading-5 px-2">
              <p class="text-[#222222]">{{ image }}</p>
              <b class="font-normal text-[#2F4A89]">Remove file</b>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4 pb-2">
        <button
          type="submit"
          class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
        >
          <span>Send message</span>
        </button>
      </div>
    </Form>
  </div>
</template>
<script>
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';
export default {
  name: 'ContactSupport',
  components: {
    Field,
    Form,
  },
  setup() {
    const schema = Yup.object().shape({
      subject: Yup.string().required('Subject field is required'),
      message: Yup.string().required('Message field cannot be empty'),
    });
    const user_store = useUserStore();
    return { user_store, schema };
  },
  data() {
    return {
      image: '',
    };
  },
  methods: {
    upload(e) {
      let a = e.target.files[0];
      this.image = a?.name;
    },
    onSubmit(values) {
      this.user_store.setLoading(true);
      const toast = useToast();
      const payload = {
        subject: values.subject,
        message: values.message,
        file: this.image,
        messageId: 1,
      };
      console.log(payload);
      this.user_store
        .composeOrReplyMessage(payload)
        .then((res) => {
          console.log(res);
          if (res.status === true) {
            this.user_store.setLoading(false);
            toast.success('Message was successfully sent');
            this.$emit('NagivateInbox')
          } else {
            this.user_store.setLoading(false);
            toast.error('Message failed to send');
          }
        })
        .catch((error) => {
          this.user_store.setLoading(false);
          toast.error('Message failed to send');
          console.log(error);
        });
    },
  },
};
</script>
<style lang=""></style>
