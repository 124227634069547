import { http } from '@/helper/util';

export default {
  // user api
  loginUser(payload) {
    return http.post('/login', payload);
  },
  registerUser(payload) {
    return http.post('/registration', payload);
  },
  getUserProfile() {
    return http.get('/member/profile');
  },
  updateUserProfile(payload) {
    return http.post('/member/profile/update', payload);
  },
  changeUserPassword(payload) {
    return http.post('/member/profile/change-password', payload);
  },
  resetUserPassword(payload) {
    return http.post('/reset-password', payload);
  },
  verifyUserOTP(payload) {
    return http.post('/verify-otp', payload);
  },
  setNewPassword(id, payload) {
    return http.post(`/set-password/${id}`, payload);
  },
  composeOrReplyMessage(payload) {
    return http.post('/message', payload);
  },
  getInbox() {
    return http.get('/message/inbox');
  },
  getOutbox() {
    return http.get('/message/outbox');
  },

  // loan api
  getLoanRecords() {
    return http.get('/loan/loan-applications');
  },
  getLoanRepaymentRecords() {
    return http.get('/loan/loan-repayment');
  },
  getSubmittedRepaymentRecords() {
    return http.get('/loan/record-payments');
  },
  getLoanProfiles() {
    return http.get('/loan/profile');
  },
  getLoanProducts(id) {
    return http.get(`/loan/product/${id}`);
  },
  getLoanRepaymentTypes() {
    return http.get('/loan/repayment-types');
  },
  newLoan(payload) {
    return http.post('/loan', payload);
  },
  getRunningLoans() {
    return http.get('/loan/running-loans');
  },
  loanRepayment(payload) {
    return http.post('/loan/submit-request', payload);
  },
  getLoanProduct(id) {
    return http.get(`/loan/loan-product/${id}`);
  },

  // saving api
  getSavingRecords() {
    return http.get('/savings');
  },
  getTargetedSavingRecords() {
    return http.get('/target-savings');
  },
  getSavingDeposit() {
    return http.get('/savings/deposit-logs');
  },
  newDeposit(payload) {
    return http.post('/savings/deposit', payload);
  },
  getSavingProfiles() {
    return http.get('/savings/profile');
  },

  // guarantor api
  addGuarantors(payload) {
    return http.post('/loan/add-guarantors', payload);
  },
  fetchGuarantor() {
    return http.get('/loan/fetch-guarantors');
  },
  removeMember(id) {
    return http.delete('/loan/remove-guarantors/' + id);
  },
  fetchIncomingGuarantor() {
    return http.get('/loan-guarantor/incoming');
  },
  fetchOutgoingGuarantor() {
    return http.get('/loan-guarantor/outgoing');
  },
  fetchDashboardIncomingGuarantor() {
    return http.get('/loan-guarantor/incoming-home');
  },
  acceptRequest(id) {
    return http.get(`/loan-guarantor/accept-request/${id}`);
  },
  declineRequest(id) {
    return http.get(`/loan-guarantor/decline-request/${id}`);
  },
  negotiatewithGuarantor(id, payload) {
    return http.post(`/loan-guarantor/update/${id}`, payload);
  },

  // others
  getCompanyProfile() {
    return http.get('/company');
  },
  getAgency() {
    return http.get('/agency');
  },
  getBanks() {
    return http.get('/bank');
  },
};
