import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import './main.css';
import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes.js';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Store
import { createPinia } from 'pinia';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
  components,
  directives,
});

const options = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

app.use(vuetify);
app.use(Toast, options);
app.use(router);
app.use(createPinia());
app.mount('#app');
