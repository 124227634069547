<template>
  <div>
    <PageLayout />
    <div class="w-full min-h-screen bg-[#f8f9fd] overflow-hidden">
      <div class="pt-20 lg:pl-[16%]">
        <div class="px-4">
          <p
            @click="showLoanRequest = false"
            class="text-sm font-medium cursor-pointer hidden lg:block sm:block"
          >
            Guarantors
          </p>
          <!-- Mobile Responsive -->
          <div class="lg:hidden sm:hidden block pt-3">
            <div class="flex justify-between items-center">
              <svg
                v-if="showLoanRequest"
                @click="showLoanRequest = false"
                class="mb-2"
                width="20"
                height="20"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9173 6.50016H1.58398M1.58398 6.50016L6.25065 11.1668M1.58398 6.50016L6.25065 1.8335"
                  stroke="#334DAA"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p
                v-else
                @click="showLoanRequest = false"
                class="text-xl font-medium cursor-pointer"
              >
                Guarantor
              </p>
              <button
                v-if="!showLoanRequest"
                class="bg-[#2F4A89] py-2 px-4 flex items-center gap-2 rounded-md justify-between text-white"
                @click="active === 'incoming' ? (showLoanRequest = true) : ''"
              >
                <p v-if="active === 'incoming'" class="text-sm">
                  New loan request
                </p>
                <p v-else class="text-sm">Submit Loan repayment request</p>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9993 6.83317H6.83268V10.9998C6.83268 11.4582 6.45768 11.8332 5.99935 11.8332C5.54102 11.8332 5.16602 11.4582 5.16602 10.9998V6.83317H0.999349C0.541016 6.83317 0.166016 6.45817 0.166016 5.99984C0.166016 5.5415 0.541016 5.1665 0.999349 5.1665H5.16602V0.999837C5.16602 0.541504 5.54102 0.166504 5.99935 0.166504C6.45768 0.166504 6.83268 0.541504 6.83268 0.999837V5.1665H10.9993C11.4577 5.1665 11.8327 5.5415 11.8327 5.99984C11.8327 6.45817 11.4577 6.83317 10.9993 6.83317Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          
          <div
            v-if="!showLoanRequest"
            class="my-4 lg:bg-white sm:bg-white bg-[#f8f9fd] pt-3 lg:rounded-lg sm:rounded-lg"
          >
            <!-- First Header -->
            <div class="border-b bg-white">
              <div class="flex justify-between items-center pb-1 w-full">
                <div class="flex gap-6">
                  <button
                    @click="active = 'incoming'"
                    :class="[
                      'flex items-center font-medium text-sm gap-2',
                      active === 'incoming' ? 'text-[#334DAA]' : 'text-[#666666]',
                    ]"
                  >
                    <p class="pl-4">Incoming</p>
                    <p
                      :class="[
                        'px-2 py-1 rounded-md',
                        active === 'incoming' ? 'bg-[#EEF0F9]' : 'bg-[#F0F0F0]',
                      ]"
                    >
                      {{
                        guarantor_store?.incomingGuarantors?.data
                          ? guarantor_store?.incomingGuarantors?.data.length
                          : 0
                      }}
                    </p>
                  </button>
                  <button
                    @click="active = 'outgoing'"
                    :class="[
                      'flex items-center font-medium text-sm gap-2',
                      active === 'outgoing'
                        ? 'text-[#334DAA]'
                        : 'text-[#666666]',
                    ]"
                  >
                    <p>Outgoing</p>
                    <p
                      :class="[
                        'px-2 py-1 rounded-md',
                        active === 'outgoing'
                          ? 'bg-[#EEF0F9]'
                          : 'bg-[#F0F0F0]',
                      ]"
                    >
                      {{
                        guarantor_store?.outgoingGuarantors?.data
                          ? guarantor_store?.outgoingGuarantors?.data.length
                          : 0
                      }}
                    </p>
                  </button>
                </div>
                <div class="hidden lg:block sm:block">
                  <button
                    class="bg-[#2F4A89] py-2 px-4 mr-4 flex items-center gap-2 rounded-lg justify-between text-white"
                    @click="active === 'incoming' ? (showLoanRequest = true) : ''"
                  >
                    <p v-if="active === 'incoming'" class="text-sm">
                      New loan request
                    </p>
                    <p v-else class="text-sm">Submit Loan repayment request</p>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.33337 6.00016H10.6667M10.6667 6.00016L6.00004 1.3335M10.6667 6.00016L6.00004 10.6668"
                        stroke="white"
                        stroke-width="1.67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <!-- Mobile responsive -->
                <div class="lg:hidden sm:hidden block">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_131_104)">
                      <path
                        d="M10.3334 9.33326H9.80672L9.62005 9.15326C10.4201 8.21993 10.8334 6.9466 10.6067 5.59326C10.2934 3.73993 8.74672 2.25993 6.88005 2.03326C4.06005 1.6866 1.68672 4.05993 2.03338 6.87993C2.26005 8.7466 3.74005 10.2933 5.59338 10.6066C6.94672 10.8333 8.22005 10.4199 9.15338 9.61993L9.33338 9.8066V10.3333L12.1667 13.1666C12.4401 13.4399 12.8867 13.4399 13.1601 13.1666C13.4334 12.8933 13.4334 12.4466 13.1601 12.1733L10.3334 9.33326ZM6.33338 9.33326C4.67338 9.33326 3.33338 7.99326 3.33338 6.33326C3.33338 4.67326 4.67338 3.33326 6.33338 3.33326C7.99338 3.33326 9.33338 4.67326 9.33338 6.33326C9.33338 7.99326 7.99338 9.33326 6.33338 9.33326Z"
                        fill="#666666"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_131_104">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div
                :class="[
                  active === 'incoming'
                    ? 'bg-[#334DAA] w-28 py-[1.5px]'
                    : 'ml-28 bg-[#334DAA] w-[130px] py-[1.5px]',
                ]"
              ></div>
            </div>

            <!-- Second Header -->
            <div class="lg:block sm:block hidden">
              <div class="p-4 flex justify-between items-center">
                <div class="flex items-center gap-6">
                  <button
                    class="flex items-center gap-3 border border-[#E1E1E1] px-3 py-1 rounded-3xl"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.80665 10.9935C4.68665 10.8935 2.99998 9.14683 2.99998 7.00016C2.99998 4.7935 4.79331 3.00016 6.99998 3.00016C9.14665 3.00016 10.8933 4.68683 10.9933 6.80683L9.59331 6.38683C9.31998 5.20683 8.25998 4.3335 6.99998 4.3335C5.52665 4.3335 4.33331 5.52683 4.33331 7.00016C4.33331 8.26016 5.20665 9.32016 6.38665 9.5935L6.80665 10.9935ZM13.6666 7.00016C13.6666 7.20016 13.66 7.40016 13.64 7.60016L12.3266 7.20683C12.3333 7.14016 12.3333 7.06683 12.3333 7.00016C12.3333 4.0535 9.94665 1.66683 6.99998 1.66683C4.05331 1.66683 1.66665 4.0535 1.66665 7.00016C1.66665 9.94683 4.05331 12.3335 6.99998 12.3335C7.06665 12.3335 7.13998 12.3335 7.20665 12.3268L7.59998 13.6402C7.39998 13.6602 7.19998 13.6668 6.99998 13.6668C3.31998 13.6668 0.333313 10.6802 0.333313 7.00016C0.333313 3.32016 3.31998 0.333496 6.99998 0.333496C10.68 0.333496 13.6666 3.32016 13.6666 7.00016ZM11.1533 9.84016L12.6666 9.3335C12.9733 9.2335 12.9666 8.7935 12.66 8.70016L7.59331 7.18016C7.33998 7.10683 7.09998 7.34016 7.17998 7.5935L8.69998 12.6602C8.79331 12.9735 9.23331 12.9802 9.33331 12.6668L9.83998 11.1535L12.4466 13.7602C12.58 13.8935 12.7866 13.8935 12.92 13.7602L13.7666 12.9135C13.9 12.7802 13.9 12.5735 13.7666 12.4402L11.1533 9.84016Z"
                        fill="#334DAA"
                      />
                    </svg>
                    <p class="text-[#444444] text-sm font-medium">Action</p>
                  </button>
                  <button
                    class="flex items-center gap-3 border border-[#E1E1E1] px-3 py-1 rounded-3xl"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.80665 10.9935C4.68665 10.8935 2.99998 9.14683 2.99998 7.00016C2.99998 4.7935 4.79331 3.00016 6.99998 3.00016C9.14665 3.00016 10.8933 4.68683 10.9933 6.80683L9.59331 6.38683C9.31998 5.20683 8.25998 4.3335 6.99998 4.3335C5.52665 4.3335 4.33331 5.52683 4.33331 7.00016C4.33331 8.26016 5.20665 9.32016 6.38665 9.5935L6.80665 10.9935ZM13.6666 7.00016C13.6666 7.20016 13.66 7.40016 13.64 7.60016L12.3266 7.20683C12.3333 7.14016 12.3333 7.06683 12.3333 7.00016C12.3333 4.0535 9.94665 1.66683 6.99998 1.66683C4.05331 1.66683 1.66665 4.0535 1.66665 7.00016C1.66665 9.94683 4.05331 12.3335 6.99998 12.3335C7.06665 12.3335 7.13998 12.3335 7.20665 12.3268L7.59998 13.6402C7.39998 13.6602 7.19998 13.6668 6.99998 13.6668C3.31998 13.6668 0.333313 10.6802 0.333313 7.00016C0.333313 3.32016 3.31998 0.333496 6.99998 0.333496C10.68 0.333496 13.6666 3.32016 13.6666 7.00016ZM11.1533 9.84016L12.6666 9.3335C12.9733 9.2335 12.9666 8.7935 12.66 8.70016L7.59331 7.18016C7.33998 7.10683 7.09998 7.34016 7.17998 7.5935L8.69998 12.6602C8.79331 12.9735 9.23331 12.9802 9.33331 12.6668L9.83998 11.1535L12.4466 13.7602C12.58 13.8935 12.7866 13.8935 12.92 13.7602L13.7666 12.9135C13.9 12.7802 13.9 12.5735 13.7666 12.4402L11.1533 9.84016Z"
                        fill="#334DAA"
                      />
                    </svg>
                    <p class="text-[#444444] text-sm font-medium">Action</p>
                  </button>
                </div>
                <div class="relative">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="absolute mt-3 ml-2"
                  >
                    <g clip-path="url(#clip0_131_104)">
                      <path
                        d="M10.3334 9.33326H9.80672L9.62005 9.15326C10.4201 8.21993 10.8334 6.9466 10.6067 5.59326C10.2934 3.73993 8.74672 2.25993 6.88005 2.03326C4.06005 1.6866 1.68672 4.05993 2.03338 6.87993C2.26005 8.7466 3.74005 10.2933 5.59338 10.6066C6.94672 10.8333 8.22005 10.4199 9.15338 9.61993L9.33338 9.8066V10.3333L12.1667 13.1666C12.4401 13.4399 12.8867 13.4399 13.1601 13.1666C13.4334 12.8933 13.4334 12.4466 13.1601 12.1733L10.3334 9.33326ZM6.33338 9.33326C4.67338 9.33326 3.33338 7.99326 3.33338 6.33326C3.33338 4.67326 4.67338 3.33326 6.33338 3.33326C7.99338 3.33326 9.33338 4.67326 9.33338 6.33326C9.33338 7.99326 7.99338 9.33326 6.33338 9.33326Z"
                        fill="#999999"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_131_104">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <input
                    type="text"
                    placeholder="Search"
                    class="border border-[#E1E1E1] w-[270px] rounded-lg py-2 px-7 text-sm"
                  />
                </div>
              </div>
            </div>

            <!--Incoming Table Component -->
            <div v-if="active === 'incoming'">
              <GuarantorTable
                :tableHeader="header"
                :tableData="guarantor_store?.incomingGuarantors?.data"
                tableType="incoming"
              />
            </div>

            <!-- OutGoing Table Component -->
            <div v-if="active === 'outgoing'">
              <GuarantorTable
                :tableHeader="header"
                :tableData="guarantor_store?.outgoingGuarantors?.data"
                tableType="outgoing"
              />
            </div>
          </div>
          <div v-if="showLoanRequest">
            <NewLoanVue />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import GuarantorTable from '@/components/Tables/GuarantorTable.vue';
import NewLoanVue from '@/components/NewLoan.vue';
import { useGuarantorStore } from '@/stores/guarantor';

export default {
  name: 'GuarantorsPage',
  components: { PageLayout, GuarantorTable, NewLoanVue },
  setup() {
    const guarantor_store = useGuarantorStore();
    return { guarantor_store };
  },
  data() {
    return {
      header: [
        {
          id: 1,
          name: 'Sender',
        },
        {
          id: 2,
          name: 'Phone Number	',
        },
        {
          id: 3,
          name: 'Liability',
        },
        {
          id: 4,
          name: 'Status',
        },
        {
          id: 5,
          name: 'Details',
        },
      ],
      active: 'incoming',
      showLoanRequest: false,
    };
  },
  mounted() {
    console.log('showLoanRequest', this.showLoanRequest);
  },
  created() {
    this.guarantor_store.fetchIncomingGuarantor().catch((error) => {
      console.log(error);
    });
    this.guarantor_store.fetchOutingGuarantor().catch((error) => {
      console.log(error);
    });
  },
};
</script>
<style lang=""></style>
