import { defineStore } from 'pinia';
import savingService from '@/helper/services/api';

export const useSavingStore = defineStore('savingStore', {
  state: () => ({
    savingsRecords: [],
    targetedRecords: [],
    depositLog: [],
    banks: [],
    savingProfiles: [],
    loading: false,
  }),
  getters: {
    loadingStatus: (state) => state.loading,
  },
  actions: {
    setLoading(status) {
      this.loading = status;
    },
    async fetchSavingRecords() {
      try {
        this.loading = true;
        const { data } = await savingService.getSavingRecords();
        this.savingsRecords = data;
        this.loading = false;
        // console.log('savings records', data);
        return data;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async fetchTargetedSavingRecords() {
      try {
        this.loading = true;
        const { data } = await savingService.getTargetedSavingRecords();
        this.targetedRecords = data;
        this.loading = false;
        console.log('targeted saving records', data);
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchSavingDeposit() {
      try {
        this.loading = true;
        const { data } = await savingService.getSavingDeposit();
        this.depositLog = data;
        this.loading = false;
        console.log('deposit log', data);
        return data;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchSavingProfiles() {
      try {
        const { data } = await savingService.getSavingProfiles();
        this.savingProfiles = data;
        // console.log('savings profiles', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchBanks() {
      try {
        const { data } = await savingService.getBanks();
        this.banks = data;
        // console.log('banks records', data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async newDeposit(payload) {
      try {
        const res = await savingService.newDeposit(payload);
        return { data: res.data, status: true };
      } catch (error) {
        console.log(error);
        return { data: null, status: false };
      }
    },
  },
});
