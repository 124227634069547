<template>
  <div class="h-[140px] lg:w-[24%] sm:w-[24%] w-[48%] rounded-lg p-[16px] gap-4" :style="{background: `${bgColor}`}">
    <div :class="['w-[40px] h-[40px] bg-white text-center mb-5 rounded-sm flex items-center justify-center']">
      <img :src="image" alt="icon" />
    </div>
    <p class="text-xs uppercase font-medium tracking-wide mb-1 text-black">{{ cardTitle }}</p>
    <p class="font-bold text-xl" :style="{color: `${textColor}`}">{{ subTotal ? subTotal : 0 }}</p>
  </div>
</template>
<script>
export default {
  name: 'CardComponent',
  props: {
    bgColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: ''
    },
    cardTitle: {
      type: String,
      required: true,
    },
    subTotal: {
      type: String,
      default: ''
    },
  },
  mounted() {
  }
}
</script>
<style lang="">
  
</style>