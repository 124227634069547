<template>
  <div class="flex justify-center mb-5">
    <div
      class="bg-[#FFFFFF] lg:w-[495px] sm:w-[495px] w-[375px] p-6 rounded-[10px] border-b border-[#F5F5F5] z-50 shadow-lg shadow-neutral-200/50"
    >
      <div>
        <div class="pb-6">
          <p class="text-[#000000] font-bold text-xl pb-1 tracking-wide">
            Loan Repayment
          </p>
        </div>

        <form class="lg:w-[441px] sm:w-[441px] w-[331px] mx-auto" @submit.prevent="onSubmit">
          <div class="flex flex-col w-full pb-4 relative">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Select a running loan
            </label>
            <div class="absolute right-2 top-8">
              <img src="@/assets/img/inputMore.svg" alt="more" />
            </div>
            <select
              class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              name="running-loan"
              id="running-loan"
              placeholder="running-loan"
              v-model="runningLoan"
            >
              <option value="" disabled>Running loan</option>
              <option
                v-for="item in loan_store?.runningLoans"
                :key="item.id"
                :value="item.id"
              >
                {{ item.loan_product.name }} - {{ formatCurrency(item.principal_bal) }}
              </option>
            </select>
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Enter amount to pay</label
            >
            <input
              class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              placeholder="₦ 0.00"
              v-model="amount"
            />
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Select payment method</label
            >
            <div class="flex gap-5 w-full">
              <div
                :class="[
                  'rounded-lg w-1/2 px-4 py-2',
                  activePayment === 'cash'
                    ? 'bg-[#F4F7FF] border-2 border-[#7C91D0]'
                    : 'bg-[#FAFAFA]',
                ]"
                @click="activePayment = 'cash'"
              >
                <div class="flex justify-end">
                  <img
                    v-if="activePayment === 'cash'"
                    src="@/assets/img/checkActive.svg"
                    alt="active"
                  />
                  <img v-else src="@/assets/img/check.svg" alt="check" />
                </div>
                <div class="flex flex-col items-center justify-center pb-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_238_347)">
                      <path
                        d="M23.75 8V18C23.75 19.1 22.85 20 21.75 20H5.75C5.2 20 4.75 19.55 4.75 19C4.75 18.45 5.2 18 5.75 18H21.75V8C21.75 7.45 22.2 7 22.75 7C23.3 7 23.75 7.45 23.75 8ZM4.75 16C3.09 16 1.75 14.66 1.75 13V7C1.75 5.34 3.09 4 4.75 4H16.75C18.41 4 19.75 5.34 19.75 7V14C19.75 15.1 18.85 16 17.75 16H4.75ZM7.75 10C7.75 11.66 9.09 13 10.75 13C12.41 13 13.75 11.66 13.75 10C13.75 8.34 12.41 7 10.75 7C9.09 7 7.75 8.34 7.75 10Z"
                        fill="#334DAA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_238_347">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.75)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="text-[#444444] text-sm pt-1">Cash payment</p>
                </div>
              </div>
              <div
                :class="[
                  'rounded-lg w-1/2 px-4 py-2',
                  activePayment === 'transfer'
                    ? 'bg-[#F4F7FF] border-2 border-[#7C91D0]'
                    : 'bg-[#FAFAFA]',
                ]"
                @click="activePayment = 'transfer'"
              >
                <div class="flex justify-end">
                  <img
                    v-if="activePayment === 'transfer'"
                    src="@/assets/img/checkActive.svg"
                    alt="active"
                  />
                  <img v-else src="@/assets/img/check.svg" alt="check" />
                </div>
                <div class="flex flex-col items-center justify-center pb-2">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_238_357)">
                      <path
                        d="M20.25 4H4.25C3.14 4 2.26 4.89 2.26 6L2.25 18C2.25 19.11 3.14 20 4.25 20H20.25C21.36 20 22.25 19.11 22.25 18V6C22.25 4.89 21.36 4 20.25 4ZM19.25 18H5.25C4.7 18 4.25 17.55 4.25 17V12H20.25V17C20.25 17.55 19.8 18 19.25 18ZM20.25 8H4.25V6H20.25V8Z"
                        fill="#334DAA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_238_357">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0.25)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p class="text-[#444444] text-sm pt-1">Transfer/Deposit</p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full pb-4 relative">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Select bank
            </label>
            <div class="absolute right-2 top-8">
              <img src="@/assets/img/inputMore.svg" alt="more" />
            </div>
            <select
              class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              name="savings-type"
              id="savings-type"
              placeholder="savings type"
              v-model="bankType"
            >
              <option value="" disabled>Access bank</option>
              <option
                v-for="item in saving_store?.banks"
                :key="item.bank.id"
                :value="item.bank.id"
              >
                {{ item.bank.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-col w-full pb-4">
            <label class="text-[#222222] text-[13px] font-medium pb-1"
              >Narration</label
            >
            <input
              class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
              type="text"
              v-model="narration"
            />
          </div>
          <div class="pt-4 pb-2">
            <button
              type="submit"
              class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
            >
              <span>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { useLoanStore } from '@/stores/loan';
import { useSavingStore } from '@/stores/saving';
import { useToast } from 'vue-toastification';
import { formatCurrency } from "@/helper/util"

export default {
  name: 'LoanRepayment',
  setup() {
    const loan_store = useLoanStore();
    const saving_store = useSavingStore();
    return { saving_store, loan_store };
  },
  data() {
    return {
      runningLoan: '',
      amount: '',
      activePayment: '',
      bankType: '',
      narration: '',
    };
  },
  mounted() {
    // console.log('I am main',this.activePayment)
  },
  created() {
    this.loan_store.fetchRunningLoans().catch((error) => {
      console.log(error);
    });
    this.saving_store.fetchBanks().catch((error) => {
      console.log(error);
    });
  },
  methods: {
    onSubmit() {
      this.saving_store.setLoading(true);
      const toast = useToast();

      const payload = {
        loanProfile: this.runningLoan,
        // repaymentLog: 2,
        amount: this.amount,
        paymentOption: this.activePayment,
        selectAccount: this.bankType,
        narration: this.narration,
        // file: null,
      };

      this.loan_store
        .loanRepayment(payload)
        .then((res) => {
          console.log(res);
          console.log({ payload });
          if (res.data) {
            this.saving_store.setLoading(false);
            toast.success('Loan Repayment Deposit successful');
          } else {
            this.saving_store.setLoading(false);
            toast.error('Saving Deposit failed');
          }
        })
        .catch((error) => {
          this.saving_store.setLoading(false);
          toast.error('Loan Repayment Deposit failed');
          console.log(error);
        });
    },
    formatCurrency(currency) {
      return formatCurrency(currency)
    },
  },
};
</script>
<style lang=""></style>
