<template>
  <div class="flex justify-center">
    <div
      class="bg-[#FFFFFF] lg:w-[495px] sm:w-[495px] w-[375px] p-6 rounded-[10px] border-b border-[#F5F5F5] shadow-lg shadow-neutral-200/50"
    >
      <div>
        <div v-if="view !== 'review'" class="pb-6">
          <p class="text-[#000000] font-bold text-xl pb-1 tracking-wide">
            New loan request
          </p>
          <p class="text-[#666666] text-sm font-normal">
            Complete the form to make a loan request
          </p>
        </div>

        <form
          class="lg:w-[441px] sm:w-[441px] w-[331px] mx-auto"
          @submit.prevent="onSubmit"
        >
          <!-- review -->
          <div v-if="view === 'review'">
            <p class="text-[#000000] font-bold text-xl">Review and submit</p>
            <div class="pt-6 pb-4">
              <p class="text-[#888888] text-[10px] font-bold">LOAN TYPE</p>
              <p class="text-[#222222] font-medium text-xs">
                {{ loanDetails?.loanType == 1 ? 'CASH LOAN' : loanDetails?.loanType == 2 ? 'MORGAGE LOAN' :  loanDetails?.loanType == 3 ? 'CAPITAL LOAN' : loanDetails?.loanType == 4 ? 'GRANT' : null}}
              </p>
            </div>
            <div class="pb-4">
              <p class="text-[#888888] text-[10px] font-bold uppercase">
                LOAN PRODUCT
              </p>
              <p class="text-[#222222] font-medium text-xs">
                {{ loanDetails?.loanProduct }}
              </p>
            </div>
            <div class="pb-4">
              <p class="text-[#888888] text-[10px] font-bold uppercase">
                LOAN AMOUNT
              </p>
              <p class="text-[#222222] font-medium text-xs">NGN {{ formatNumber(loanDetails?.loanAmount) }}</p>
            </div>
            <div class="pb-4">
              <p class="text-[#888888] text-[10px] font-bold uppercase">
                LOAN INTEREST RATE
              </p>
              <p class="text-[#222222] font-medium text-xs">{{ loanDetails?.interestRate }}</p>
            </div>
            <div class="pb-4">
              <p class="text-[#888888] text-[10px] font-bold uppercase">
                monthly Repayment
              </p>
              <p class="text-[#222222] font-medium text-xs">
                {{ formatNumber(loanDetails.monthlyRepayment) }}
              </p>
            </div>
            <div class="pb-4">
              <p class="text-[#888888] text-[10px] font-bold uppercase">
                total loan
              </p>
              <p class="text-[#222222] font-medium text-xs">NGN {{ formatNumber(loanDetails?.totalLoan) }}</p>
            </div>
            <div class="pt-4 pb-2 flex gap-2">
              <button
                @click="view = 'secondStage'"
                class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
              >
                <!-- <span v-if="loan_store.loadingStatus">Loading...</span> -->
                <span>Back</span>
              </button>
              <button
                type="submit"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
              >
                <span>Submit</span>
              </button>
            </div>
          </div>

          <!-- first stage -->
          <div v-if="view === 'firstStage'">
            <div class="flex flex-col w-full pb-4 relative">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Select loan type</label
              >
              <div class="absolute right-2 top-8">
                <img src="@/assets/img/inputMore.svg" alt="more" />
              </div>
              <select
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                name="loan-type"
                id="loan-type"
                placeholder="Loan type"
                v-model="newLoan.loanType"
                @change="selectedLoanProducts(newLoan.loanType)"
              >
                <option value="" disabled>Loan type</option>
                <option
                  v-for="item in loan_store?.loanProfiles"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="flex flex-col w-full pb-4 relative">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Select loan product</label
              >
              <div class="absolute right-2 top-8">
                <img src="@/assets/img/inputMore.svg" alt="more" />
              </div>
              <select
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                name="loan-product"
                id="loan-product"
                placeholder="Loan product"
                v-model="newLoan.loanProduct"
                @change="selectedLoanProduct(newLoan.loanProduct)"
              >
                <option value="" disabled>Loan Product</option>
                <option
                  v-for="item in loan_store?.loanProducts"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <div class="flex flex-col w-full pb-4">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Enter loan amount</label
              >
              <input
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="text"
                placeholder="₦ 2 000 000"
                @keyup="calc(newLoan.loanAmount)"
                v-model="newLoan.loanAmount"
              />
            </div>
            <div class="flex flex-col w-full pb-4">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Loan interest</label
              >
              <input
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="text"
                placeholder="₦ 200,000"
                v-model="newLoan.loanInterest"
                readonly
              />
            </div>
            <div class="flex flex-col w-full pb-4">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Total loan amount</label
              >
              <input
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="text"
                placeholder="₦ 2 200 000"
                v-model="newLoan.totalLoan"
                readonly
              />
            </div>

            <div class="text-white pt-4 pb-2">
              <button
                @click="view = 'secondStage'"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full"
              >
                <span>Next</span>
              </button>
            </div>
          </div>

          <!-- second stage -->
          <div v-if="view === 'secondStage'">
            <div class="flex flex-col w-full pb-4 relative">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Repayment model</label
              >
              <div v-for="item in loan_store?.repaymentTypes" :key="item.id">
                <input
                  class="mt-1"
                  type="radio"
                  :value="item.id"
                  v-model="newLoan.repaymentMethod"
                />
                <span class="text-sm px-1">{{ item.name }}</span>
              </div>
            </div>
            <div class="flex flex-col w-full pb-4">
              <label class="text-[#222222] text-[13px] font-medium pb-1"
                >Monthly Repayment</label
              >
              <input
                class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                type="text"
                placeholder="₦ 200,000"
                v-model="newLoan.monthlyRepayment"
                readonly
              />
            </div>
            <div class="pb-4 w-full">
              <p class="text-[#222222] text-[13px] font-bold pb-1">Guarantor</p>
              <div class="flex flex-row w-full items-center">
                <div class="flex flex-col w-[40%]">
                  <label class="text-[#222222] text-[13px] font-medium pb-1"
                    >Member ID</label
                  >
                  <input
                    class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                    type="text"
                    placeholder="Member ID"
                    v-model="newLoan.memberId"
                  />
                </div>
                <div class="flex flex-col w-[40%]">
                  <label class="text-[#222222] text-[13px] font-medium pb-1"
                    >Enter Amount</label
                  >
                  <input
                    class="border border-[#F5F5F5] rounded-sm px-4 py-3 text-xs focus:outline-none focus:ring-0 focus:ring-offset-0"
                    type="text"
                    placeholder="Enter Amount"
                    v-model="newLoan.amount"
                  />
                </div>
                <div class="flex flex-col w-[25%] ml-[3%]">
                  <label class="text-[#222222] text-[13px] font-medium pb-1"
                    >Add
                  </label>
                  <button
                    @click="addGuarantor"
                    class="flex items-center justify-center bg-[#D8E3FF] lg:px-4 sm:px-4 px-2 py-3 rounded-[4px]"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_347_465)">
                        <path
                          d="M11.9997 8.66683H8.66634V12.0002C8.66634 12.3668 8.36634 12.6668 7.99967 12.6668C7.63301 12.6668 7.33301 12.3668 7.33301 12.0002V8.66683H3.99967C3.63301 8.66683 3.33301 8.36683 3.33301 8.00016C3.33301 7.6335 3.63301 7.3335 3.99967 7.3335H7.33301V4.00016C7.33301 3.6335 7.63301 3.3335 7.99967 3.3335C8.36634 3.3335 8.66634 3.6335 8.66634 4.00016V7.3335H11.9997C12.3663 7.3335 12.6663 7.6335 12.6663 8.00016C12.6663 8.36683 12.3663 8.66683 11.9997 8.66683Z"
                          fill="#334DAA"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_347_465">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span
                      class="text-[#334DAA] text-[13px] font-medium"
                      >Add</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- choose gaurantor -->
            <div class="pb-2 w-full max-h-[180px] overflow-scroll">
              <div
                class="flex flex-row py-2 px-3 bg-[#F5F5F5] rounded-t-md w-full"
              >
                <p class="w-1/2 text-[#222222] text-[13px] font-medium">
                  Member
                </p>
                <p class="w-1/2 text-[#222222] text-[13px] font-medium">
                  Amount
                </p>
              </div>
              <div>
                <div
                  class="flex flex-row py-1 px-3 mb-2 border-x border-b border-[#F5F5F5] rounded-t-md w-full"
                  v-for="mem in guarantor_store.pendingGuarantors
                    .loan_guarantor"
                  :key="mem.id"
                >
                  <div class="w-1/2">
                    <p
                      class="text-[#222222] text-[13px] font-normal capitalize"
                    >
                      {{ mem.member?.firstName }} {{ mem.member?.lastName }}
                    </p>
                  </div>
                  <div
                    class="w-1/2 text-[#222222] text-[13px] font-normal flex justify-between items-center"
                  >
                    <span>{{ formatNumber(mem?.liability) }}</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                      @click="handleModal(mem)"
                    >
                      <g clip-path="url(#clip0_347_512)">
                        <path
                          d="M3.99967 12.6667C3.99967 13.4 4.59967 14 5.33301 14H10.6663C11.3997 14 11.9997 13.4 11.9997 12.6667V6C11.9997 5.26667 11.3997 4.66667 10.6663 4.66667H5.33301C4.59967 4.66667 3.99967 5.26667 3.99967 6V12.6667ZM11.9997 2.66667H10.333L9.85967 2.19333C9.73967 2.07333 9.56634 2 9.39301 2H6.60634C6.43301 2 6.25967 2.07333 6.13967 2.19333L5.66634 2.66667H3.99967C3.63301 2.66667 3.33301 2.96667 3.33301 3.33333C3.33301 3.7 3.63301 4 3.99967 4H11.9997C12.3663 4 12.6663 3.7 12.6663 3.33333C12.6663 2.96667 12.3663 2.66667 11.9997 2.66667Z"
                          fill="#CCCCCC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_347_512">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p class="text-[#444444] text-xs font-bold">
                Total liability added:
                <span>
                  {{
                    formatNumber(
                      guarantor_store.pendingGuarantors.amount_request
                    )
                  }}
                </span>
                of
                <span>
                  {{
                    formatNumber(
                      guarantor_store.pendingGuarantors.total_loan_amount
                    )
                  }}
                </span>
              </p>
            </div>
            <div class="pt-4 pb-2 flex gap-2">
              <button
                @click="view = 'firstStage'"
                class="bg-[#F5F5F5] rounded-md h-[40px] w-full"
              >
                <span>Back</span>
              </button>
              <button
                @click="setLocalStorage"
                class="bg-[#2F4A89] rounded-md h-[40px] w-full text-white"
              >
                <span>Next</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <DeleteDialog
    v-if="current_guarantor && dialog"
    @deleteAction="deleteGuarantor($event)"
    @dismiss="handleDismiss"
  />
</template>
<script>
import { useLoanStore } from '@/stores/loan';
import { useGuarantorStore } from '@/stores/guarantor';
import { useToast } from 'vue-toastification';
import { formatNumber } from '@/helper/util';
import DeleteDialog from './global/DeleteDialog.vue';
import deleteService from '@/helper/services/api';


export default {
  name: 'NewLoan',
  components: { DeleteDialog },
  setup() {
    const loan_store = useLoanStore();
    const guarantor_store = useGuarantorStore();
    return { loan_store, guarantor_store };
  },
  data() {
    return {
      view: 'firstStage',
      file: null,
      newLoan: {
        loanType: '',
        loanProduct: '',
        loanAmount: '',
        loanInterest: '',
        interestRate: '',
        loanPeriod: '',
        totalLoan: '',
        repaymentMethod: 1,
        memberId: '',
        monthlyRepayment: 0,
        amount: '',
      },
      getPeriod: [],
      current_guarantor: null,
      dialog: false,
      preventSubmit: false,
    };
  },
  created() {
    this.loan_store.fetchLoanProfiles().catch((error) => {
      console.log(error);
    });
    this.loan_store.fetchLoanRepaymentTypes().catch((error) => {
      console.log(error);
    });
    this.guarantor_store.fetchGuarantor().catch((error) => {
      console.log(error);
    });
  },
  computed: {
    loanDetails() {
      const loanDetails = JSON.parse(
        localStorage.getItem('new_loan') || null
      );
      return loanDetails;
    },
  },

  methods: {
    setLocalStorage() {
      localStorage.setItem('new_loan', JSON.stringify(this.newLoan));

      console.log(localStorage.getItem('new_loan'));
      this.loanDetails;
      this.view = 'review';
    },

    clearLoanDetails() {
      localStorage.removeItem('new_loan');
    },

    selectedLoanProducts() {
      this.loan_store
        .fetchLoanProducts(this.newLoan.loanType)
        .catch((error) => {
          console.log(error);
        });
    },

    selectedLoanProduct(value) {
      this.loan_store
        .fetchLoanProduct(value)
        .then((response) => {
          const loanAmount = this.newLoan.loanAmount || '0';
          this.newLoan.loanProduct = response.id;
          this.newLoan.interestRate = response.interest / 100;
          this.newLoan.loanPeriod = Math.round(response.duration / 30);
          this.newLoan.loanInterest =
            this.newLoan.loanAmount * this.newLoan.interestRate;
          this.newLoan.totalLoan =
            parseInt(this.newLoan.loanInterest) + parseInt(loanAmount);
          this.newLoan.monthlyRepayment =
            parseInt(this.newLoan.totalLoan) /
            parseInt(this.newLoan.loanPeriod);

          this.getPeriod = [];

          for (let i = 1; i <= this.newLoan.loanPeriod; i++) {
            this.getPeriod.push(i);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    calc(value) {
      this.newLoan.loanInterest = value * this.newLoan.interestRate;
      this.newLoan.totalLoan =
        parseInt(this.newLoan.loanInterest) + parseInt(value);
      this.newLoan.monthlyRepayment =
        parseInt(this.newLoan.totalLoan) / parseInt(this.newLoan.loanPeriod);
    },

    addGuarantor() {
      this.preventSubmit = true;
      const toast = useToast();
      const payload = {
        guarantorId: this.newLoan.memberId,
        guarantorAmount: this.newLoan.amount,
        total_loan: this.newLoan.totalLoan,
      };
      this.guarantor_store
        .addGuarantors(payload)
        .then((res) => {
          console.log('check added guarantor',res)
          if (res.data.status === 201) {
            this.guarantor_store.pendingGuarantors
            this.guarantor_store.fetchGuarantor()
            toast.success(res.data.data.message);
          } else {
            toast.error(res.data.response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('Unable to add Guarantor');
        });
    },

    async deleteGuarantor() {
      const toast = useToast();
      this.dialog = false;
      try {
        const res = await deleteService.removeMember(this.current_guarantor.id);
        console.log('deleted guarantor', res);
        if(res.status === 201) {
          this.guarantor_store.removeGuarantor(this.current_guarantor.id);
          toast.success('Guarantor removed successfully');
        }
      } catch (error) {
        console.log(error);
        toast.error('Failed to delete guarantor');
      }
    },

    handleModal(guarantor) {
      this.current_guarantor = guarantor;
      this.dialog = true;
    },

    handleDismiss() {
      this.dialog = false;
    },

    onSubmit() {
      if (this.preventSubmit) {
        this.preventSubmit = false;
        return;
      }
      this.loan_store.setLoading(true);
      const toast = useToast();

      const payload = {
        loanProfile: this.newLoan.loanType,
        loanProduct: this.newLoan.loanProduct,
        amount: this.newLoan.loanAmount,
        interest: this.newLoan.loanInterest,
        paymentOption: this.newLoan.repaymentMethod,
        totalAmount: this.newLoan.totalLoan,
      };
      this.loan_store
        .newLoan(payload)
        .then((res) => {
          if (res.status === true) {
            this.loan_store.setLoading(false);
            toast.success('Your loan request has been submitted.');
            this.clearLoanDetails();
          } else {
            this.loan_store.setLoading(false);
            toast.error(res.response.data.message);
            this.clearLoanDetails();
          }
        })
        .catch((error) => {
          this.loan_store.setLoading(false);
          this.clearLoanDetails();
          toast.error('You have a pending loan request');
          console.log(error);
        });
    },

    formatNumber(currency) {
      return formatNumber(currency);
    },
  },
};
</script>
<style lang="scss"></style>
